import React from 'react';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import {Container} from './styles'

type Props = {
  dtcolor?: string
}

const Load: React.FC<Props> = (prop: Props) => {
  return (
    <Container>
      <Loader
        type="BallTriangle"
        color={prop.dtcolor ? prop.dtcolor : "#1E90FF"}
        height={100}
        width={100}
        timeout={3000} //3 secs
      />
    </Container>
  );
};

export default Load;
