import React, { useState, useEffect } from "react";
import { MdRestaurant, MdPlace, MdNearMe, MdSchedule } from "react-icons/md";
import {
  FiBox,
  FiShoppingCart,
  FiChevronRight,
  FiSearch,
  FiX,
  FiTrash,
} from "react-icons/fi";
import { useHistory, useParams } from "react-router-dom";
import { format, parse } from "date-fns";
/* Context */
import { useMenu } from "../../context/MenuContext";
/* Image */
import logo from "../../assets/images/logo.png";
/* Components */
import Load from "../../components/Load";

import Services from "../../services/Services";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import AnchorLink from "react-anchor-link-smooth-scroll";

/* Styles */
import {
  Container,
  Header,
  HeaderFooter,
  Logo,
  LogoSmall,
  Content,
  Info,
  Status,
  Address,
  Products,
  Footer,
  RefContainer,
  RefItem,
  Radio,
  RadioActive,
} from "./styles";

const MenuPage: React.FC = () => {
  const history = useHistory();
  const params = useParams<any>();
  const {
    bag,
    address,
    updateOrder,
    addressSelected,
    selectedAddress,
    removeAddress,
  } = useMenu();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>();
  const [total, setTotal] = useState<number>(500);
  const [opened, setOpened] = useState(false);
  const [dataProducts, setDataProducts] = useState<any>();
  const [dataGpProducts, setDataGpProducts] = useState<any>();
  const [dataProductsPromo, setDataProductsPromo] = useState<any>();

  const [viewHeader, setViewHeader] = useState<boolean>(true);
  const [heightHeader, setHeightHeader] = useState<any>(
    window.innerWidth > 769 ? 160 : 150
  );

  useEffect(() => {
    const fetchData = async () => {
      await Services.products(params?.empresa).then((response) => {
        setDataProducts(response.data);
        // setDataProductsPromo(productsPromo);
      });

      await Services.productsPromo(params?.empresa).then((response) => {
        if (response && response.data && response.data.length > 0) {
          setDataProductsPromo(response.data[0].produtos);
        }else{
          setDataProductsPromo([]);
        }

        // var productsPromo = response.data.filter((entry: any) => {
        //   return entry.em_promocao == true;
        // });

        // setDataProductsPromo(productsPromo);
      });

      await Services.group_products(params?.empresa).then((response) => {
        setDataGpProducts(response.data);
      });

      setLoading(false);
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    let dt: any = localStorage.getItem("@menu-digital:data");
    setData(JSON.parse(dt));

    if (params.empresa !== JSON.parse(dt).empresa) {
      history.push(`/${params.empresa}`);
    }

    getOpened(JSON.parse(dt));

    if (bag !== null && bag.length > 0) {
      let cont = 0;
      bag.forEach((element) => {
        cont = cont + element.valor_total * element.quantidade;
      });
      setTotal(cont);
    }

    window.addEventListener("scroll", function () {
      let header_height: any = document.getElementById("header")?.clientHeight;
      let header_ref: any = document.getElementById("ref")?.offsetTop;
      let yOffset = 0;

      yOffset = document.documentElement.scrollTop + 60;
      setHeightHeader(header_height);
      if (yOffset >= header_height) {
        document.getElementById("header")?.classList.add("minimized");
        setViewHeader(false);
      } else {
        document.getElementById("header")?.classList.remove("minimized");
        setViewHeader(true);
      }

      if (yOffset >= header_ref) {
        document.getElementById("ref")?.classList.add("minimized");
      } else {
        document.getElementById("ref")?.classList.remove("minimized");
      }
    });

    localStorage.setItem("@menu-digital:search", "");
  }, [bag, history, params]);

  const getOpened = (dt: any) => {
    const day = format(new Date(), "i");
    const now = new Date();
    const openingTime = parse(dt.hora_abre, "HH:mm", new Date());
    const closingTime = parse(dt.hora_fecha, "HH:mm", new Date());

    // Verifica se o estabelecimento funciona durante a noite (fechamento maior que abertura)
    const isOpenDuringNight = closingTime < openingTime;

    // Define os horários de abertura e fechamento corretos, levando em conta a possibilidade de funcionamento durante a noite
    const open = openingTime;
    const close = closingTime;

    let isCurrentlyOpen;
    // Verifica se o horário atual está dentro do intervalo de funcionamento
    if (isOpenDuringNight) {
      isCurrentlyOpen =
        (now >= open || now <= close) &&
        now >= parse("00:00", "HH:mm", new Date()) &&
        now <= parse("23:59", "HH:mm", new Date());
    } else {
      isCurrentlyOpen = now >= open && now <= close;
    }

    // Verifica se o estabelecimento está aberto no dia da semana atual
    const isTodayOpen =
      (day === "1" && dt.abre_segunda) ||
      (day === "2" && dt.abre_terca) ||
      (day === "3" && dt.abre_quarta) ||
      (day === "4" && dt.abre_quinta) ||
      (day === "5" && dt.abre_sexta) ||
      (day === "6" && dt.abre_sabado) ||
      (day === "7" && dt.abre_domingo);

    // Define a variável opened com base nas condições de funcionamento
    setOpened(isCurrentlyOpen && isTodayOpen);

    // Verifica se o horário atual está fora do intervalo de funcionamento e é depois das 22:00 ou antes das 08:00
    if (!isCurrentlyOpen && (now.getHours() < 8 || now.getHours() >= 22)) {
      setOpened(false);
    }
  };

  const money = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  });

  if (loading) {
    return <Load dtcolor={data?.primary_color} />;
  }

  const filterGpProduct = (entry: any, data: any) => {
    return entry.id_grupo == data.id_grupo;
  };

  const filterPromotionTamanhoArrayMenor = (tamanho: any) => {
    let arrayValores = [];

    if (tamanho.valor_g_promocao != undefined) {
      arrayValores.push(tamanho.valor_g_promocao);
    } else {
      arrayValores.push(tamanho.valor_g);
    }

    if (tamanho.valor_m_promocao != undefined) {
      arrayValores.push(tamanho.valor_m_promocao);
    } else {
      arrayValores.push(tamanho.valor_m);
    }

    if (tamanho.valor_p_promocao != undefined) {
      arrayValores.push(tamanho.valor_p_promocao);
    } else {
      arrayValores.push(tamanho.valor_p);
    }

    return Math.min(...arrayValores);
  };

  const filterPromotionTamanhoArrayMaior = (tamanho: any) => {
    let arrayValores = [];

    if (tamanho.valor_g_promocao != undefined) {
      arrayValores.push(tamanho.valor_g_promocao);
    } else {
      arrayValores.push(tamanho.valor_g);
    }

    if (tamanho.valor_m_promocao != undefined) {
      arrayValores.push(tamanho.valor_m_promocao);
    } else {
      arrayValores.push(tamanho.valor_m);
    }

    if (tamanho.valor_p_promocao != undefined) {
      arrayValores.push(tamanho.valor_p_promocao);
    } else {
      arrayValores.push(tamanho.valor_p);
    }

    return Math.max(...arrayValores);
  };

  const options = {
    responsive: {
      0: {
        items: 3,
        autoWidth: true,
      },
      400: {
        items: 3,
        autoWidth: true,
      },
      600: {
        items: 5,
      },
      700: {
        items: 6,
      },
      1000: {
        items: 9,
      },
    },
  };

  const optionsPromo = {
    responsive: {
      0: {
        items: 2,
        autoWidth: true,
      },
      400: {
        items: 2,
        autoWidth: true,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <Container style={{ paddingTop: heightHeader }}>
      <Header id="header" style={{ backgroundColor: data?.primary_color }}>
        {viewHeader && <Logo src={`${data?.logo_home}`} alt={data?.nome} />}
        <HeaderFooter>
          <p
            onClick={() =>
              history.push({
                pathname: `/${data.empresa}/about`,
                state: { detail: data },
              })
            }
            style={{ width: "auto" }}
          >
            Sobre a loja
            <FiChevronRight color="#fff" size={20} />
          </p>
          <FiSearch
            color="#fff"
            size={20}
            onClick={() =>
              history.push({
                pathname: `/${data.empresa}/search`,
                state: { detail: data },
              })
            }
          />
        </HeaderFooter>
      </Header>
      <Content>
        <Info>
          <div className="item">
            <MdRestaurant size={20} color="#7f8f9f" />
            <p>{data?.segmento}</p>

            {opened ? (
              <Status>
                <MdSchedule />
                Aberto
              </Status>
            ) : (
              <Status style={{ backgroundColor: "#F08080" }}>
                <MdSchedule />
                Fechado
              </Status>
            )}
          </div>
          <div className="item">
            <MdPlace size={20} color="#7f8f9f" />
            <p>
              {data?.endereco}, {data?.numero} - {data?.bairro}, {data?.cidade}/
              {data?.estado}
            </p>
          </div>
        </Info>

        {/* <Address href="#delivery" style={{ borderColor: data?.primary_color }}>
          <div style={{ width: "10%", maxWidth: 40 }} >
            <MdNearMe size={20} color={data?.primary_color} />
          </div>
          {addressSelected.id !== undefined ?
            <div className="address" style={{ color: data?.primary_color }}>
              <p>{addressSelected.bairro}</p>
              <p>{addressSelected.logradouro} - {addressSelected.numero}</p>
              <div className="row">
                <strong>Entrega aprox.</strong>
                <div className="tag" style={{background: data?.primary_color}}>40 - 70 min</div>
                <div className="tag" style={{background: data?.primary_color}}>{money.format(addressSelected?.valor_taxa)}</div>
              </div>
            </div>
            :
            <p style={{ color: data?.primary_color }}>Selecione o endereço de entrega</p>
          }
        </Address>

        <div className="modal" id="delivery">
          <div className="modal__content">
            <a href="#/" className="modal__content__close" style={{background: data?.primary_color}}>
              <FiX color="#fff" size={40} />
            </a>
            <div className="modal_header">
              <h1>Endereço para entrega</h1>
            </div>
            <div className="modal_content">
              <div className="modal_item" onClick={() => history.push(`/${data.empresa}/delivery`)}>
                <div style={{ display: "flex", color: data?.primary_color }} >
                  <a href="#/">
                    <Radio></Radio>
                  </a>
                  <p>Novo Endereço</p>
                </div>
              </div>
              {address.map((element: any, index: any) => (
                <a
                  href="#/"
                  className="modal_item"
                  key={index}
                  onClick={() => {
                    updateOrder(element)
                    selectedAddress(element)
                  }}
                >
                  <div style={{ display: "flex", width: "90%", color: data?.primary_color }} >
                    <Radio>
                      {element.id === addressSelected.id && <RadioActive />}
                    </Radio>
                    <div>
                      <p>{element.logradouro} - {element.number}</p>
                      <span>{element.bairro}, {element.uf}-{element.cidade}</span>
                    </div>
                  </div>
                  <FiTrash color={data?.primary_color} onClick={() => removeAddress(element.id)} />
                </a>
              ))}
            </div>
          </div>
        </div> */}

        <RefContainer id="ref" header={heightHeader}>
          <OwlCarousel margin={10} nav items={9} {...options}>
            {dataGpProducts?.map(
              (item: any, index: number) =>
                dataProducts?.filter((entry: any) =>
                  filterGpProduct(entry, item)
                ).length > 0 && (
                  <AnchorLink
                    offset="200px"
                    style={{ textDecoration: "none" }}
                    href={`#${item.descricao}`}
                  >
                    <RefItem key={item.id_produto} className="scrollto">
                      <div className="itemIcon">
                        <div
                          className="retangulo"
                          style={{ backgroundColor: data?.accent_color }}
                        >
                          <img src={item.icone} alt={item.descricao} />
                        </div>
                      </div>
                      <span>{item.descricao}</span>
                    </RefItem>
                  </AnchorLink>
                )
            )}
          </OwlCarousel>
        </RefContainer>

        <Products>
          <div className="top_section"></div>

          {data.gratis_acima_de > 0 && (
            <div className="info">
              <div>
                <FiBox size={20} color="#30333c" />
                <p>
                  Frete grátis em pedidos acima de{" "}
                  {money.format(data.gratis_acima_de)}
                </p>
              </div>
            </div>
          )}

          {dataProductsPromo.length > 0 && (
            <section className="promocoes">
              <h1>PROMOÇÃO DO DIA</h1>
              <div className="section_container">
                <div className="listProduts">
                  <OwlCarousel margin={10} nav items={3} dots {...optionsPromo}>
                    {dataProductsPromo?.map((product: any) => (
                      <div
                        key={"promo_" + product.id}
                        className={`item ${
                          product.foto_destaque == undefined ? "noImage" : ""
                        }`}
                        onClick={() =>
                          history.push({
                            pathname: `/${data.empresa}/detail/${
                              dataGpProducts?.filter((entry: any) =>
                                filterGpProduct(entry, product)
                              )[0].id_grupo
                            }/${product.id}`,
                          })
                        }
                      >
                        {product.foto_destaque !== undefined ? (
                          <div className="imageProduct">
                            <img
                              src={product.foto_destaque}
                              alt={product.descricao}
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        <div
                          className={`productInfo ${
                            product.foto_destaque == undefined
                              ? "w-100_wImg"
                              : ""
                          }`}
                        >
                          <div className="infosTop">
                            <h2>{product?.descricao}</h2>
                            <p>{product?.detalhe}</p>

                            {product?.serve_qtd_pessoas ? (
                              <span className="serve">
                                Serve {product?.serve_qtd_pessoas} pessoa
                                {product?.serve_qtd_pessoas > 1 ? "s" : ""}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <h2
                            className={`price ${
                              product?.em_promocao == true
                                ? "pricepromocao"
                                : ""
                            }`}
                          >
                            {product.tamanhos && <span>De: </span>}

                            {["P", "O"].indexOf(product.tipo) > -1 && (
                              <div>
                                {money.format(product.valor_de)}{" "}
                                <span>até: </span>
                                {/* {product?.em_promocao == false ? money.format(product?.valor_de) : (filterPromotionTamanho(product) < product?.valor_de ? money.format(filterPromotionTamanho(product)) : money.format(product?.valor_de))} <span>até: </span> */}
                                {money.format(product.valor_ate)}
                              </div>
                            )}
                            {["P", "O"].indexOf(product.tipo) === -1 &&
                              (product?.em_promocao == false
                                ? money.format(product?.valor)
                                : money.format(product?.valor_promocao))}
                            {["P", "O"].indexOf(product.tipo) === -1 &&
                            product?.em_promocao == true ? (
                              <span className="valorwhitoutpromotion">
                                {product?.em_promocao == true &&
                                  money.format(product?.valor)}
                              </span>
                            ) : (
                              ""
                            )}
                          </h2>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>
            </section>
          )}

          {dataGpProducts?.map(
            (item: any) =>
              dataProducts?.filter((entry: any) => filterGpProduct(entry, item))
                .length > 0 && (
                <section key={item.id} id={item.descricao}>
                  <h1>{item?.descricao}</h1>
                  <div className="section_container">
                    <div className="listProduts">
                      {dataProducts
                        ?.filter((entry: any) => filterGpProduct(entry, item))
                        ?.map((product: any) => (
                          <div
                            key={product.id_produto}
                            className="item"
                            onClick={() =>
                              history.push({
                                pathname: `/${data.empresa}/detail/${
                                  dataGpProducts?.filter((entry: any) =>
                                    filterGpProduct(entry, product)
                                  )[0].id_grupo
                                }/${product.id_produto}`,
                              })
                            }
                          >
                            <div
                              className={`productInfo ${
                                product.foto_destaque == undefined
                                  ? "w-100_wImg"
                                  : ""
                              }`}
                            >
                              <div className="infosTop">
                                <h2>{product?.descricao}</h2>
                                <p>{product?.detalhe}</p>

                                {product?.serve_qtd_pessoas ? (
                                  <span className="serve">
                                    Serve {product?.serve_qtd_pessoas} pessoa
                                    {product?.serve_qtd_pessoas > 1 ? "s" : ""}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                              <h2
                                className={`price ${
                                  product?.em_promocao == true
                                    ? "pricepromocao"
                                    : ""
                                }`}
                              >
                                {product.tamanhos && <span>De: </span>}

                                {["P", "O"].indexOf(product.tipo) > -1 && (
                                  <div>
                                    {money.format(product?.valor_de)}{" "}
                                    <span>até: </span>
                                    {money.format(product?.valor_ate)}
                                  </div>
                                )}
                                {["P", "O"].indexOf(product.tipo) === -1 &&
                                  (product?.em_promocao == false
                                    ? money.format(product?.valor)
                                    : money.format(product?.valor_promocao))}
                                {["P", "O"].indexOf(product.tipo) === -1 &&
                                product?.em_promocao == true ? (
                                  <span className="valorwhitoutpromotion">
                                    {product?.em_promocao == true &&
                                      money.format(product?.valor)}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </h2>
                            </div>

                            {product.foto_destaque !== undefined ? (
                              <div className="imageProduct">
                                <img
                                  src={product.foto_destaque}
                                  alt={product.descricao}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                </section>
              )
          )}
        </Products>
      </Content>
      {bag !== null && bag.length > 0 ? (
        <Footer
          onClick={() => history.push(`/${data.empresa}/order`)}
          style={{ backgroundColor: data?.primary_color }}
        >
          <div className="item">
            <FiShoppingCart size={18} />
            <div className="circle">{bag.length}</div>
          </div>
          <div className="item">
            <p>Meu Pedido</p>
          </div>
          <div className="item">
            <strong>{money.format(total)}</strong>
          </div>
        </Footer>
      ) : null}
    </Container>
  );
};

export default MenuPage;
