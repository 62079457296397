import styled from 'styled-components';

export const Container = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #1E90FF;
  padding-top: 6rem;
  position: absolute;
  bottom: 0;
`;

export const Content = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  h2{
    text-align: center;
    color:#fff;
  }
`;

export const LogoSmall = styled.img`
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
`;

export const Logo = styled.img`
  width: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
`;

export const Footer = styled.div`
  display:flex;
  background-color: #fff;
  font-size: 1rem;
  color:#fff;
  font-family: Roboto, sans-serif;
  height: 4rem;
  width:100%;
  align-items: center;
  justify-content: center;
`;
