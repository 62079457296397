import React, { useState, useEffect } from 'react';
import { MdNearMe } from "react-icons/md";
import {
  FiArrowLeft,
  FiArrowRight,
  FiMoreVertical,
  FiCheckCircle,
  FiX
} from "react-icons/fi";
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
/* Services */
import Services from '../../services/Services';
/* Context */
import { useMenu } from '../../context/MenuContext'
/* Styles */
import {
  Container,
  Header,
  Content,
  Footer,
  Nav,
  Required,
  Radio,
  RadioActive,
  Cart,
  Button,
  Address,
  Result
} from './styles';
import Load from '../../components/Load';

const OrderPage: React.FC = () => {
  const history = useHistory()
  const params = useParams<any>()
  const {
    bag,
    data,
    removeCart,
    order,
    address,
    updateOrder,
    reset,
    addressSelected,
    selectedAddress,
    loadStorage
  } = useMenu()

  const [total, setTotal] = useState<number>(0)
  const [totalTaxa, setTotalTaxa] = useState<number>(0)
  const [name, setName] = useState<string>("")
  const [phone, setPhone] = useState<string>("")
  const [note, setNote] = useState<string>("")
  const [delivery, setDelivery] = useState<undefined | number>()
  const [loadingOrder, setLoadingOrder] = useState<undefined | number>(0)
  const [addressIndex] = useState<undefined | number>();
  const [totalSabores, setTotalSabores] = useState(0);

  useEffect(() => {
    loadStorage(data);
  }, []);

  useEffect(() => {
    if (params.empresa !== data.empresa) {
      history.push(`/${params.empresa}/order`)
    }

    if (order.nome !== undefined) {
      setName(order.nome)
    }
    if (order.celular !== undefined) {
      setPhone(order.celular)
    }
    if (order.observacao !== undefined) {
      setNote(order.observacao)
    }
    if (order.operacao === "DELIVERY") {
      setDelivery(1)
    }
    if (order.operacao === "RETIRAR") {
      setDelivery(2)
    }

    if (bag !== null && bag.length > 0) {
      let cont = 0
      bag.forEach(element => {
        cont = cont + (element.valor_total * element.quantidade)
      });
      setTotal(cont)  
    } else {
      setTotal(0)
      // history.push(`/${data.empresa}/menu`)
    }


    bag.forEach(item => {
      if (item.adicional == undefined) {
        item.adicional = [];
      }
    })

    var historyDelivery = localStorage.getItem('deliveryOption');

    if (historyDelivery != undefined) {

      setDelivery(parseInt(historyDelivery))
    }

  }, [bag, order, history, params, data])

  useEffect(() => {
    if(data.entrega_gratis && data.gratis_acima_de != 0 && total > data.gratis_acima_de && order.operacao == "ENTREGA"){
      updateOrder({
        valor_taxa: 0
      })
    }else if(order.operacao == "ENTREGA" && addressSelected.valor_taxa !== undefined){
      if(addressSelected.valor_taxa != order.valor_taxa){
        updateOrder({
          valor_taxa: addressSelected.valor_taxa !== undefined ? addressSelected.valor_taxa : 0
        })
      }
    }
  }, [total, data])


  const saveOrder = () => {
    let dataOrder: any = {
      nome: name,
      celular: phone,
      observacao: note
    }
    if (delivery === 1) {
      dataOrder.operacao = "ENTREGA"
    }
    if (delivery === 2) {
      dataOrder.operacao = "RETIRAR"
    }
    updateOrder(dataOrder)
  }

  const handleOrder = async () => {

    setLoadingOrder(1);

    let dataOrder: any = {
      nome: name,
      celular: phone,
      observacao: note
    }

    if (delivery === 1) {
      dataOrder.operacao = "ENTREGA"
    }
    if (delivery === 2) {
      dataOrder.operacao = "RETIRAR"
    }

    var bagNew = bag;

    // return true;

    for (let i = 0; i < bagNew.length; i++) {
      const item = bag[i];

      if (item.tipo === 'P' || item.tipo == 'O') {
        item.descricao = item.descricao.replaceAll(item.quantidade + '/' + item.sabores.length, '').trim();
        item.descricao = (item.quantidade + '/' + item.sabores.length) + ' ' + item.descricao;
        item.valor = data?.regra_valor_montagem == 'MEDIA' ? (item.total / item.sabores.length) : (Math.max.apply(Math, item.sabores?.map(function (o: any) { return o.valor; })))

        for (let x = 0; x < item.sabores.length; x++) {
          const sabor = item.sabores[x];

          sabor.descricao = sabor.descricao.replaceAll(item.quantidade + '/' + item.sabores.length, '').trim();
          sabor.descricao = (item.quantidade + '/' + item.sabores.length) + ' ' + sabor.descricao;

        }
      }
    }

    let dt: any = {
      ...order,
      ...dataOrder,
      user_id: data.user_id,
      total_produtos: total,
      itens: bagNew
    }

    delete dt.id

    dt.valor_total = total + parseFloat(dt.valor_taxa);

    await Services.order(data?.user_id, dt)
      .then(response => {
        toast("Pedido realizado com sucesso", { type: "success" })

        var celularWhats = data.numero_whats.replaceAll(' ', '')
        celularWhats = celularWhats.replaceAll('(', '')
        celularWhats = celularWhats.replaceAll(')', '')
        celularWhats = celularWhats.replaceAll('-', '')

        var textoWhats = '🔔 *Novo pedido via https://menudallas.com.br/' + data.empresa + '*\n\n'

        textoWhats += '*Cliente:* ' + dt.nome.toUpperCase() + ' ' + dt.celular + '\n\n'

        var endereco = `${dt.logradouro}, ${dt.numero} - ${dt.bairro} - (${dt.cidade}/${dt.uf})`

        if (dt.operacao.toUpperCase() != 'RETIRAR') {
          textoWhats += '*Endereço:* ' + endereco + '\n\n'
        }

        textoWhats += '*Itens:*'

        var vlAdicionais = 0;
        var vlTotal = 0;

        for (let i = 0; i < dt.itens.length; i++) {
          const item = dt.itens[i];

          var descProduto = item.tipo == 'P' ? item.tag + ' - ' + item.tamanho.tag : item.descricao
          textoWhats += '\n' + item.quantidade + ' x ' + descProduto.toUpperCase() + '\n'

          if (item.tipo == 'P') {
            vlTotal += (data?.regra_valor_montagem == 'MEDIA' ? (item.total / item.sabores.length) : (Math.max.apply(Math, item.sabores?.map(function (o: any) { return o.valor; })))) * item.quantidade
          } else {
            vlTotal += (item.valor * item.quantidade);
          }

          if (item.sabores) {
            for (let x = 0; x < item.sabores.length; x++) {
              const element = item.sabores[x];

              textoWhats += '  1/' + item.sabores.length + ' ' + (element.descricao.replaceAll(item.quantidade + '/' + item.sabores.length + ' ', '')) + '\n'
            }
          }

          if (item.adicional) {
            for (let x = 0; x < item.adicional.length; x++) {
              const element = item.adicional[x];

              if (item.adicional.length == (x + 1)) {
                textoWhats += '  ADIC. ' + element.descricao + '\n'
              } else {
                textoWhats += '  ADIC. ' + element.descricao + '\n'
              }

              vlAdicionais += (element.valor * element.quantidade);
              vlTotal += vlAdicionais
            }
          }
        }

        textoWhats += '\n*Valor dos itens:* ' + money.format(dt.total_produtos) + '\n'

        if (dt.operacao.toUpperCase() != 'RETIRAR') {
          textoWhats += '*Valor da entrega:* ' + money.format(dt.valor_taxa) + '\n'
        }

        vlTotal = vlTotal + parseFloat(dt.valor_taxa);

        textoWhats += '*Valor dos adicionais:*  ' + money.format(vlAdicionais) + '\n'
        textoWhats += '*Valor total:* ' + money.format(vlTotal) + '\n\n'

        textoWhats += '*Tipo de pagamento:* ' + dt.operacao + '\n'
        textoWhats += '*Forma de Pagamento:* ' + dt.forma + '\n'
        textoWhats += '*Troco:* ' + money.format(dt.valor_para_troco) + '\n'


        if (window.confirm("Deseja confirmar o pedido pelo Whatsapp?")) {
          if (window.screen.width < 769) {
            window.open('whatsapp://send/?phone=+55' + celularWhats + '&text=' + encodeURIComponent(textoWhats))
          } else {
            window.open('https://api.whatsapp.com/send?phone=+55' + celularWhats + '&text=' + encodeURIComponent(textoWhats), "_blank")
          }
        }

        setLoadingOrder(0);
        reset();
        history.push(`/${data.empresa}/menu`)

      }).catch(error => {
        // console.log('error', error)
        toast("Ocorreu um erro interno", { type: "error" })
      })
  }

  const money = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  });

  function removerCartFn(index: Number) {
    if (window.confirm("Deseja remover item do pedido?")) {
      removeCart(index);
    }
  }

  if (loadingOrder) {
    return (
      <Load dtcolor={data?.primary_color} />
    )
  }

  return (
    <Container>
      <Header>
        <Nav>
          <FiArrowLeft cursor="pointer" size={20} onClick={() => history.push(`/${data.empresa}/menu`)} />
          <h1>Meu pedido</h1>
          <div />
        </Nav>
        <Cart>

          <div className="item headerColumn">
            <p>Itens</p>
            <div className='column'>
              <strong>Preço</strong>
            </div>
          </div>

          {bag.map((item: any, index: number) => (
            <div key={index}>
              <div className="item itemDestaque">
                {item.tipo == 'P' || item.tipo == 'O' ? (
                  <p>{item.quantidade}<span className='x'>x</span> {item.tag} {item.tamanho != undefined ? '- ' + item.tamanho.tag : ''}</p>
                ) : (
                  <p>{item.quantidade}<span className='x'>x</span> {item.descricao}</p>
                )}

                <div className="column">
                  {item.tipo == 'P' || item.tipo == 'O' ? (
                    <strong>{data?.regra_valor_montagem == 'MEDIA' ? money.format(item.total / item.sabores.length) : money.format(Math.max.apply(Math, item.sabores?.map(function (o: any) { return o.valor; })))}</strong>
                  ) : (
                    <strong>{money.format(item.valor * item.quantidade)}</strong>
                  )}
                  <a href={`#item${index}`} className="xIconBtn" onClick={() => removerCartFn(index)}>
                    <span className='xIcon'>X</span>
                  </a>
                </div>
              </div>
              <div className="detail" >
                <ul key={index}>
                  {item.adicional && item.sabores && item.sabores.length > 0 &&
                    <>
                      {item.sabores.map((element: any, index: number) => (
                        <li key={index}>
                          <div className='item' style={{ paddingTop: 3 }}>
                            <p key={index} >1/{item.sabores.length} {element.descricao}</p>
                            <div className='column'>
                              <strong>***</strong>
                            </div>
                          </div>
                        </li>
                      ))}
                    </>
                  }
                </ul>

                <ul key={index}>
                  {item.adicional &&
                    item.adicional.map((element: any, index: number) => (
                      <li key={index} style={element.id && !element.id_sabor ? {} : { display: 'none' }}>
                        <div className='item'>
                          {element.id && !element.id_sabor && <p>{element.quantidade}<span className='x'>x</span> {element.descricao}</p>}
                          {element.id && !element.id_sabor && <div className='column'>
                            <strong>+ {money.format(element.valor)}</strong>
                          </div>}
                        </div>
                      </li>
                    ))
                  }
                </ul>

                {item.observacao_item && (
                  <p className='obsText' style={{
                    marginTop: 5,
                    fontStyle: 'italic',
                    color: '#898c92'
                  }}><b>Observações:</b> {item.observacao_item}</p>
                )}



                <div className="detalhesRodape">
                  <p>{item.quantidade} X
                    {item.tipo == 'P' || item.tipo == 'O' ? (
                      <span> {data?.regra_valor_montagem == 'MEDIA' ? money.format(item.total / item.sabores.length) : money.format(Math.max.apply(Math, item.sabores?.map(function (o: any) { return o.valor; })))}</span>
                    ) : (
                      <span> {money.format(item.valor)}</span>
                    )}

                  </p>
                  <p>Adic. {item.total_adicional ? money.format(item.total_adicional) : money.format(0)}</p>
                  <p>{money.format((item.valor_total * item.quantidade))}</p>
                </div>

              </div>
            </div>
          ))}

          <Button onClick={() => history.push(`/${data.empresa}/menu`)} style={{ marginTop: 10, borderColor: data?.primary_color, color: data?.primary_color }}  >Adicionar mais itens</Button>

          <div className="item itemTotal">
            <p>Total</p>
            <div className='column'>
              <strong>{money.format(total)}</strong>
            </div>
          </div>

          <div className="bottomCard"></div>
        </Cart>
      </Header>

      <Content>
        <section>
          <div className="column" style={{ paddingRight: "1rem" }} >
            <h2>Escolha uma opção</h2>
            {delivery === undefined ?
              <Required>Obrigatório</Required>
              :
              <FiCheckCircle color="#008000" size={20} />
            }
          </div>
          <article>
            <label htmlFor="Entrega">
              Entrega
              <span>(Sujeito a diponibilidade e taxas)</span>
            </label>
            <div className="content">
              <Radio onClick={() => {
                setDelivery(1)
                localStorage.setItem('deliveryOption', '1')

                let valueTaxa = addressSelected.valor_taxa !== undefined ? addressSelected.valor_taxa : 0;

                if(data.entrega_gratis && total > data.gratis_acima_de){
                  valueTaxa = 0;
                }
                updateOrder({ valor_taxa: valueTaxa, operacao: "ENTREGA" })
              }} >
                {delivery === 1 && <RadioActive style={{ background: data?.primary_color }} />}
              </Radio>
            </div>
          </article>
          {data.permite_retirar &&
            <>
              <article>
                <label htmlFor="Retirar do local">
                  <div>
                    Retirar do local
                    <span>(Sujeito a diponibilidade)</span>
                  </div>

                  {localStorage.getItem('deliveryOption') == '2' && (
                    <span style={{
                      fontSize: 12,
                      paddingLeft: 0
                    }}>
                      {data?.frase_tempo_buscar}
                    </span>
                  )}

                </label>
                <div className="content">
                  <Radio onClick={() => {
                    setDelivery(2)
                    localStorage.setItem('deliveryOption', '2')
                    updateOrder({ valor_taxa: 0, operacao: "RETIRAR" })
                  }}>
                    {delivery === 2 && <RadioActive style={{ background: data?.primary_color }} />}
                  </Radio>
                </div>
              </article>
            </>
          }
        </section>

        <section style={{ display: (delivery == 1 ? 'block' : 'none') }}>
          <div className="column" style={{ paddingRight: "1rem" }} >
            <h2>Qual o seu endereço?</h2>
            {addressSelected.id === undefined ?
              <Required>Obrigatório</Required>
              :
              <FiCheckCircle color="#008000" size={20} />
            }
          </div>

          <Address href="#delivery" style={{ borderColor: data?.primary_color }}>
            <MdNearMe size={20} color={data?.primary_color ? data?.primary_color : "#1e90ff"} />
            {addressSelected.id !== undefined ?
              <div className="address">
                <p>{addressSelected.bairro}</p>
                <p>{addressSelected.logradouro} - {addressSelected.numero}</p>
                <div className="row">
                  {data?.frase_tempo_delivery}
                  {/* <strong>Entrega aprox.</strong>
                  <div className="tag" style={{ background: data?.primary_color }}>{data?.frase_tempo_delivery}</div> */}
                  <div className="tag" style={{ background: data?.primary_color }}>{money.format(addressSelected?.valor_taxa)}</div>
                </div>
              </div>
              :
              <p>Selecione o endereço de entrega</p>
            }
          </Address>

        </section>

        <div className="modal" id="delivery">
          <div className="modal__content">
            <a href="#/" className="modal__content__close" style={{ background: data?.primary_color }}>
              <FiX color="#fff" size={40} />
            </a>
            <div className="modal_header">
              <h1>Endereço para entrega</h1>
            </div>
            <div className="modal_content">
              <div className="modal_item" onClick={() => history.push(`/${data.empresa}/delivery`)} style={{ color: data?.primary_color }}>
                <a href="#/">
                  <Radio></Radio>
                </a>
                <p>Novo Endereço</p>
              </div>

              {address.map((element: any, index: any) => (
                <a
                  href="#/"
                  className="modal_item"
                  key={index}
                  onClick={() => {
                    updateOrder(element)
                    selectedAddress(element)
                  }}
                  style={{ color: data?.primary_color }}
                >
                  <Radio>
                    {element.id === addressSelected.id && <RadioActive style={{ background: data?.primary_color }} />}
                  </Radio>
                  <div>
                    <p>{element.logradouro} - {element.numero}</p>
                    <span>{element.bairro}, {element.uf}-{element.cidade}</span>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>

        <section>
          <div className="column">
            <h2>Qual o seu nome?</h2>
            {name.length === 0 ?
              <Required>Obrigatório</Required>
              :
              <FiCheckCircle color="#008000" size={20} />
            }
          </div>
          <input name="name" type="text" value={name} onChange={(event) => setName(event.target.value)} />
        </section>

        <section>
          <div className="column">
            <h2>Seu telefone</h2>
            {phone.length === 0 ?
              <Required>Obrigatório</Required>
              :
              <FiCheckCircle color="#008000" size={20} />
            }
          </div>
          <input
            name="phone"
            type="text"
            value={phone}
            onChange={(event) =>
              setPhone(event.target.value
                .replace(/\D/g, "")
                .substring(0, 11)
                .replace(/^(\d{2})(\d)/g, "($1) $2")
                .replace(/(\d)(\d{4})$/, "$1-$2")
              )
            } />

          <p>
            O telefone é utilizado para fazer seu cadastro no restaurante e agilizar os próximos pedidos
          </p>
        </section>

        <section style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }} >
          <div className="column" style={{ width: "100%", padding: 0, paddingRight: "1rem", marginTop: "1rem", marginBottom: "1rem" }}>
            <h2>Como você vai pagar?</h2>
            {order.id_forma === undefined ?
              <Required>Obrigatório</Required>
              :
              <FiCheckCircle color="#008000" size={20} />
            }
          </div>
          {order.id_forma === undefined ?
            <Button style={{ width: "90%", borderColor: data?.primary_color, color: data?.primary_color }} onClick={() => {
              saveOrder()
              history.push(`/${data.empresa}/payment`)
            }} >Formas de pagamento</Button>
            :
            <Button style={{ width: "90%", borderColor: data?.primary_color, color: data?.primary_color }} onClick={() => {
              saveOrder()
              history.push(`/${data.empresa}/payment`)
            }} >{order.forma}</Button>
          }
        </section>

        <section>
          <div className="column">
            <h2>Observação</h2>
          </div>
          <textarea
            name="obsevacao"
            value={note}
            onChange={(event) => setNote(event.target.value)}
          ></textarea>
          <p>
            Converse diretamente com o estabelecimento caso queira modificar algum item. Neste campo não são aceitas modificações que podem gerar cobrança adicional.
          </p>
        </section>

        <Result>
          <section>
            <h2>Valor do pedido</h2>
            <strong>{bag.length > 0 && money.format(total)}</strong>
          </section>
          {(order.valor_taxa !== undefined && delivery === 1) &&
            <section>
              <h2>Taxa de entrega</h2>
              <strong>{money.format(order.valor_taxa)}</strong>
            </section>
          }
          <section>
            <h2>Valor Total</h2>
            <strong>{money.format(total + (order.valor_taxa !== undefined && delivery === 1 ? order.valor_taxa : 0))}</strong>
          </section>
          {order.valor_para_troco !== undefined &&
            <section>
              <h2>Troco para</h2>
              <strong>{money.format(order.valor_para_troco)}</strong>
            </section>
          }
        </Result>

      </Content>

      <Footer
        style={{
          background: delivery !== undefined &&
            (delivery == 1 ? addressSelected.id !== undefined : true) &&
            name.length > 0 &&
            phone.length > 0 &&
            bag.length > 0 &&
            order.id_forma !== undefined ? (data?.primary_color ? data?.primary_color : "#1e90ff") : "#485460"
        }}
        onClick={() => {
          if (bag.length == 0) {
            toast("Não é possível finalizar o pedido sem itens no carrinho", { type: "error" });
            return history.push(`/${params.empresa}/menu`);
          }

          if (
            bag.length > 0 &&
            delivery !== undefined &&
            (delivery == 1 ? addressSelected.id !== undefined : true) &&
            name.length > 0 &&
            phone.length > 0 &&
            order.id_forma !== undefined
          ) {
            var answer = window.confirm("Deseja finalizar o pedido?");
            if (answer) {
              handleOrder()
            }
          } else {
            toast("Preencha todos os campos obrigatórios", { type: "warning" })
          }
        }}
      >
        <h1>Finalizar pedido</h1>
        <FiArrowRight size={20} />
      </Footer>
    </Container >
  );
};

export default OrderPage;