import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color:rgb(243, 245, 247);
  height: 100vh;
`;
export const Header = styled.header`
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;

  h2{
    font-size: 16px;
    font-weight: bold;
    line-height: 120%;
    padding: 0.5rem 1rem;
  }

  p {
    font-family: Roboto, sans-serif;
    margin-left: 10px;
    width: 100%;
    display: flex;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 17px;
    color: #7f8f9f;
    line-height: 120%;
    padding: 0.5rem;
  }
`;

export const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 4rem;
  border-bottom: 1px solid rgb(232, 234, 237);

  padding: 1rem;

  h1{
    margin-right: 35px;
    font-size: 15px;
    line-height: 150%;
    font-weight: 500;
    text-align: center;
    color: rgb(48, 51, 60);
  }
`;

export const Divider = styled.div`
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 15px;
  width: 100%;
  background-color: #fff;
  background-image: radial-gradient(circle at 10px 26px, #f3f5f7 20px, transparent 21px);
  content: "";
  background-size: 40px 40px;
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  section{
    max-width: 800px;
    width: 90%;
    padding: 1rem 1rem;

    h1{
      font-family: Roboto, sans-serif;
      font-size: 14px;
      font-weight: bold;
      line-height: 150%;
      color:#30333c;
      display: flex;
      align-items: center;
    }
    
    p{
      font-family: Roboto, sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 150%;
      color:#30333c;
      margin: 10px 5px;
      display: flex;
      align-items: center;
    }
  }
`;
