import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { addDays, addHours, format, parse } from 'date-fns'
/* Context */
import { useMenu } from '../../context/MenuContext'
/* Services */
import Services from '../../services/Services'
/* Components */
import Button from '../../components/Button';
import Load from '../../components/Load';
/* Logo */
import logo from '../../assets/images/logo.png'
/* Styles */
import { Container, Content, Footer, Logo, LogoSmall } from './styles';
import { Helmet } from 'react-helmet';

const OnboardingPage: React.FC = () => {
  const history = useHistory()
  const params = useParams<any>()
  const { session, data, reset } = useMenu()

  const [loadinng, setLoading] = useState(true)
  const [element, setElement] = useState<any>({})
  const [opened, setOpened] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      await Services.index(params?.empresa)
        .then(response => {

          if (response.data.retorno) {
            history.push({
              pathname: '/',
              state: { message: response.data.retorno, empresa: params?.empresa }
            })
          }

          session({ empresa: params.empresa, ...response.data[0] })
          setElement(response.data[0])
          getOpened(response.data[0])
          setLoading(false)
        }).catch(error => {
          history.push({
            pathname: `/`,
          })
          setLoading(false)
        })
    }

    fetchData()
      .catch(console.error);

  }, []);

  const getOpened = (dt: any) => {
    const day = format(new Date(), "i");
    const now = new Date();
    const openingTime = parse(dt.hora_abre, "HH:mm", new Date());
    const closingTime = parse(dt.hora_fecha, "HH:mm", new Date());
  
    // Verifica se o estabelecimento funciona durante a noite (fechamento maior que abertura)
    const isOpenDuringNight = closingTime < openingTime;
  
    // Define os horários de abertura e fechamento corretos, levando em conta a possibilidade de funcionamento durante a noite
    const open = openingTime;
    const close = closingTime;
  
    let isCurrentlyOpen;
    // Verifica se o horário atual está dentro do intervalo de funcionamento
    if (isOpenDuringNight) {
      isCurrentlyOpen =
        (now >= open || now <= close) && now >= parse("00:00", "HH:mm", new Date()) && now <= parse("23:59", "HH:mm", new Date());
    } else {
      isCurrentlyOpen = now >= open && now <= close;
    }
  
    // Verifica se o estabelecimento está aberto no dia da semana atual
    const isTodayOpen = (
      (day === "1" && dt.abre_segunda) ||
      (day === "2" && dt.abre_terca) ||
      (day === "3" && dt.abre_quarta) ||
      (day === "4" && dt.abre_quinta) ||
      (day === "5" && dt.abre_sexta) ||
      (day === "6" && dt.abre_sabado) ||
      (day === "7" && dt.abre_domingo)
    );
  
    // Define a variável opened com base nas condições de funcionamento
    setOpened(isCurrentlyOpen && isTodayOpen);
  
    // Verifica se o horário atual está fora do intervalo de funcionamento e é depois das 22:00 ou antes das 08:00
    if (!isCurrentlyOpen && (now.getHours() < 8 || now.getHours() >= 22)) {
      setOpened(false);
    }
  };

  if (loadinng) {
    return <Load dtcolor={data?.primary_color} />
  }

  return (
    <Container style={{ backgroundColor: element?.primary_color }}>
      <LogoSmall src={logo} alt="Menu Dallas" />
      <Content>
        <Logo src={`${element?.logo_home}`} alt={element?.nome} />
        <h2>{element?.frase_home}</h2>
      </Content>
      <Button onClick={() => history.push({
        pathname: `/${data.empresa}/menu`,
      })} style={{ color: '#000' }}>Fazer meu pedido</Button>

      <Footer style={{ backgroundColor: opened ? "#00b4a4" : "#F08080" }} >
        <p>{opened ? "Estamos abertos" : "Estamos fechados"}</p>
      </Footer>
    </Container>
  );
};

export default React.memo(OnboardingPage);
