import axios from "axios";

const api = axios.create({
  baseURL: "https://api.edenerp.com.br:8081",
  auth: {
    username: 'testserver',
    password: 'testserver'
  }
},);

export default api;
