import React, { createContext, useContext, useState, useEffect } from 'react'
import { v4 as uuid } from 'uuid'
import Services from '../services/Services';

interface MenuContextData {
  user: object;
  bag: Array<any>;
  data: any;
  order: any;
  address: any;
  addressSelected: any;
  reset: () => any;
  session: (data: any) => any;
  addCart: (data: any) => any;
  addAddress: (data: any) => any;
  selectedAddress: (data: any) => any;
  removeAddress: (data: any) => any;
  removeCart: (data: any) => any;
  updateOrder: (data: any) => any;
  loadStorage: (data: any) => any;
}

export const MenuContext = createContext<MenuContextData>({} as MenuContextData);

export const MenuProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<any>({})
  const [bag, setBag] = useState<any>([])
  const [address, setAddress] = useState<any>([])
  const [addressSelected, setAddressSelected] = useState<any>({})
  const [order, setOrder] = useState<any>({})
  const [data, setData] = useState<any>({})
  const [isFetchedAds, setIsFetchedAds] = useState<any>(false)

  useEffect(() => {
    loadStorage(data);
  }, [data?.empresa])

  useEffect(() => {
    if (addressSelected.bairro && !isFetchedAds) {
      checkAddressBase();
    }
  }, [addressSelected]);

  async function checkAddressBase() {
    await Services.districts(data.empresa)
      .then(response => {
        const checkAdds = response.data.filter((entry: any) => entry.bairro.toUpperCase() === addressSelected.bairro.toUpperCase());

        if (checkAdds.length > 0) {
          setAddressSelected({
            ...addressSelected,
            valor_taxa: checkAdds[0].valor
          })
        }

        setIsFetchedAds(true);
      })
  }

  function loadStorage(data: any) {
    const user: any = localStorage.getItem("@menu-digital:user")
    const b: any = localStorage.getItem("@menu-digital:bag")
    const dt: any = localStorage.getItem("@menu-digital:data")
    const or: any = localStorage.getItem("@menu-digital:order")

    const ad: any = localStorage.getItem("@menu-digital:address_" + data?.empresa)
    const adSel: any = localStorage.getItem("@menu-digital:addressSelected_" + data?.empresa)

    if (b !== null) {
      setBag(JSON.parse(b))
    }
    if (or !== null) {
      setOrder(JSON.parse(or))
    }
    if (ad !== null) {
      setAddress(JSON.parse(ad))
    }else{
      setAddress([])
    }
    if (adSel !== null) {
      setAddressSelected(JSON.parse(adSel))
    }else{
      setAddressSelected({})
    }
    setUser(JSON.parse(user))
    setData(JSON.parse(dt))
  }

  const reset = () => {
    localStorage.removeItem("@menu-digital:order")
    setOrder({})
    localStorage.removeItem("@menu-digital:bag")
    setBag([])
    localStorage.removeItem("@menu-digital:addressSelected_" + data?.empresa)
    setAddressSelected({})
  }

  const session = (values: any) => {
    localStorage.removeItem("@menu-digital:data")
    localStorage.setItem("@menu-digital:data", JSON.stringify(values))
    setData(values)
  }

  const addAddress = (values: any) => {
    localStorage.removeItem("@menu-digital:address_" + data.empresa)
    let dt = address
    let newAd = { ...values, id: uuid() }
    dt.push(newAd)
    setAddress(dt)
    selectedAddress(newAd)
    localStorage.setItem("@menu-digital:address_" + data.empresa, JSON.stringify(dt))
  }

  const selectedAddress = (values: any) => {
    localStorage.removeItem("@menu-digital:addressSelected_" + data.empresa)
    setAddressSelected(values)
    localStorage.setItem("@menu-digital:addressSelected_" + data.empresa, JSON.stringify(values))
  }

  const removeAddress = (values: any) => {
    if (values.id === addressSelected.id) {
      localStorage.removeItem("@menu-digital:addressSelected_" + data.empresa)
      setAddressSelected({})
    }
    let dt = address
    let array = address.map((item: any) => item.id)
    let index = array.indexOf(values)
    dt.splice(index, 1)
    setAddress(dt)
    localStorage.setItem("@menu-digital:address_" + data.empresa, JSON.stringify(dt))
  }

  const addCart = (values: any) => {
    let or: any = localStorage.getItem("@menu-digital:order")
    if (or !== null) {
      localStorage.removeItem("@menu-digital:order")
      or = JSON.parse(or)
      delete or.id_forma
      delete or.forma
      delete or.valor_para_troco
      localStorage.setItem("@menu-digital:order", JSON.stringify(or))
      setOrder(or)
    }
    localStorage.removeItem("@menu-digital:bag")
    let dt = bag
    dt.push(values)
    setBag(dt)
    localStorage.setItem("@menu-digital:bag", JSON.stringify(dt))
  }

  const removeCart = (index: any) => {
    let or: any = localStorage.getItem("@menu-digital:order")
    if (or !== null) {
      localStorage.removeItem("@menu-digital:order")
      or = JSON.parse(or)
      delete or.id_forma
      delete or.forma
      delete or.valor_para_troco
      localStorage.setItem("@menu-digital:order", JSON.stringify(or))
      setOrder(or)
    }
    let bg: any = localStorage.getItem("@menu-digital:bag")
    bg = JSON.parse(bg)
    bg.splice(index, 1);
    setBag(bg)
    localStorage.removeItem("@menu-digital:bag")
    localStorage.setItem("@menu-digital:bag", JSON.stringify(bg))
  }

  const updateOrder = (values: any) => {
    localStorage.removeItem("@menu-digital:order")

    setOrder((prevState: any) => ({
      ...prevState,
      ...values
    }))
    localStorage.setItem("@menu-digital:order", JSON.stringify({ ...order, ...values }))
  }

  return (
    <MenuContext.Provider
      value={{
        user,
        bag,
        data,
        order,
        address,
        addressSelected,
        selectedAddress,
        reset,
        session,
        addAddress,
        removeAddress,
        addCart,
        removeCart,
        updateOrder,
        loadStorage
      }}
    >
      {children}

      <meta name="theme-color" content={data && data.primary_color ? data.primary_color : '#1e90ff'} />

    </MenuContext.Provider>
  )
}

export function useMenu(): MenuContextData {
  const context = useContext(MenuContext)

  if (!context) {
    throw new Error('useMenu must be  used within an MenuProvider')
  }

  return context

}