import api from './api'

const Services = {
  index: async (empresa: string) => {
    return await api.get(`/home?empresa=${empresa}`);
  },
  products: async (empresa: string) => {
    return await api.get(`/produtos?empresa=${empresa}`);
  },
  productsPromo: async (empresa: string) => {
    return await api.get(`/promocao?empresa=${empresa}`);
  },
  group_products: async (empresa: string) => {
    return await api.get(`/grupodeproduto?empresa=${empresa}`);
  },
  detail_product: async (empresa: string, id_grupo: Number, id_produto: Number) => {
    return await api.get(`/detalheproduto?empresa=${empresa}&id_grupo=${id_grupo}&id_produto=${id_produto}`);
  },
  search: async (name?: string, empresa?: string) => {
    return await api.get(`/produtos?empresa=${empresa}&termo=${name}`);
  },
  districts: async (empresa?: string) => {
    return await api.get(`/bairros?empresa=${empresa}`);
  },
  payments: async (empresa?: string) => {
    return await api.get(`/formapagamento?empresa=${empresa}`);
  },
  order: async (user_id: Number, params: any) => {
    return await api.post(`/pedido?user_id=${user_id}`, (btoa(unescape(encodeURIComponent(JSON.stringify(params))))), {
      headers: { 'Content-Type': 'text/plain' }
    });
  },
  flavors: async (id_grupo: any, length: any, empresa: any) => {
    return await api.get(`/sabores?tamanho=${length}&empresa=${empresa}&idgrupoproduto=${id_grupo}`);
  }
};

export default Services
