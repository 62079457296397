import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #f3f5f7;
`;
export const Header = styled.header`
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;

  h2{
    font-size: 16px;
    font-weight: bold;
    line-height: 120%;
    padding: 0.5rem 1rem;
  }

  .row{
    margin-top:10px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
`;

export const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 4rem;
  border-bottom: 1px solid rgb(232, 234, 237);

  padding: 1rem;

  h1{
    margin-right: 35px;
    font-size: 15px;
    line-height: 150%;
    font-weight: 500;
    text-align: center;
    color: rgb(48, 51, 60);
  }
`;

export const Pix = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 7px;
`;
export const PicPay = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 7px;
  object-fit: contain;
`;
export const Content = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 1rem;
  padding-bottom: 8rem;

  .modal {
    opacity: 0;
    position: fixed;
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.8);
    transition: opacity linear 0.2s;
    z-index: 5;

    display: flex;
    align-items: center;
    justify-content: center;

    overflow-y: auto;

    .modal__content{
      @media only screen and (max-width: 769px){
        width: 90%;
      }
    }

    .modal_header {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      /* border-top-left-radius: 50px;
      border-top-right-radius: 50px; */
      background-color: #fff;
      padding: 1rem;

      h1 {
        font-family: Roboto, sans-serif;
        font-size:1rem;
        width: 50%;
        text-align:center;
        color: #30333c;
      }
    }

    .modal_content {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      
      .actionDinheiro{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      a{
        border: 1px solid;
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
        text-align: center;
        color: #fff;
        text-decoration: none;
        background-color: #1e90ff;

        @media only screen and (max-width: 769px){
          font-size: 14px;
          line-height: 14px;
          width: 50%;

          &.first-child{
            width: 100%;  
          }
          
        }
      }

      .form_input {
        width: 100%;
        label {
          font-family: Roboto, sans-serif;
          font-size: 1rem;
        }

        input {
          margin: 15px 0;
          width: 100%;
          resize: none;
          outline: none;
          padding: 10px 16px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid rgb(232, 234, 237);
          padding: 10px;
          border: none;
        }
      }

      button {
        width: 100%;
        height: 50px;
      }
    }

    &:target {
      height: 100%;
      opacity: 1;
    }
    &__content {
      position: absolute;
      background: #f3f5f7;
      width: 100%;
      max-width: 1024px;
      border-top-left-radius: 50px;
      border-top-right-radius: 50px;
      z-index: 6;
      &__close {
        position: absolute;
        top: -17px;
        right: -17px;
        width: 50px;
        height: 50px;
        border-radius: 25px;
        text-align: center;
        padding: 5px;
        background: #2980b9;
        color: #fff;
      }
    }
  }

  section{
    width: 100%;
    .column{
      display: flex;
      align-items: center;
      justify-content: space-between;
      
      h2{
        font-size: 14px;
        font-weight: bold;
        color: #485460;
        margin: 1rem 0;
      }
    }

    span{
      padding: 1rem;
      margin-top: 2px;
      font-size: 12px;
      font-weight: 500;
      line-height: 150%;
      color: rgb(127, 143, 159);
    }

    p{
      width: 90%;
      margin: 8px auto 0px;
      font-size: 12px;
      line-height: 150%;
      font-weight: 500;
      color: rgb(127, 143, 159);
    }

    a{
      background-color: #fff;
      color: #485460;
      text-decoration: none;
      width: 100%;
      height: 60px;
      padding: 1rem;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
  }

`;