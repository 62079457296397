import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  body{
    font-family: 'Roboto', sans-serif;

    
    #root{
      @media (max-width:769px) {
        overflow: hidden;
      }
    }
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  @media (max-width:1080px) {
    html{
      font-size: 93.75%;
    }
  }

  @media (max-width:720px) {
    html{
      font-size: 87.5%;
    }
  }

  body, input, textarea, button{
    font:500 1rem sans-serif;
    font-family: 'Roboto', sans-serif;
  }

  h1, h2, h3, h4, h5, h6{
    font-weight: 600;
  }

  h1{
    font-size: 2rem;
  }

  h2{
    font-size: 1.5rem;
  }

  button{
    cursor: pointer;
  }
`