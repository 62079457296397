import styled from "styled-components";

export const Container = styled.button`
  display: flex;
  flex:1;
  align-items:center;
  justify-content:center;
  height: 100vh;
  width: 100vw;
`;
