import styled from "styled-components";

interface RefProps {
  header: any;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(243, 245, 247);
  min-height: 100vh;
`;
export const Header = styled.header`
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 5;

  h2 {
    font-size: 16px;
    font-weight: bold;
    line-height: 120%;
    padding: 0.5rem 1rem;
  }

  p {
    font-family: Roboto, sans-serif;
    margin-left: 10px;
    width: 100%;
    display: flex;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 17px;
    color: #7f8f9f;
    line-height: 120%;
    padding: 0.5rem;
  }

  &.minimized {
    position: fixed;
    top: 0;
    min-height: 0;
  }
`;

export const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 4rem;
  border-bottom: 1px solid rgb(232, 234, 237);

  padding: 1rem;

  h1 {
    margin-right: 35px;
    font-size: 15px;
    line-height: 150%;
    font-weight: 500;
    text-align: center;
    color: rgb(48, 51, 60);
  }

  .form_search {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-radius: 5px;

    padding: 0 1rem;
    margin-left: 1rem;
    border: 1px solid #1e90ff;

    input {
      width: 100%;
      padding: 0 15px;
      outline: none;
      border: none;
      background: transparent;
    }
  }
`;

export const Divider = styled.div`
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 15px;
  width: 100%;
  background-color: #fff;
  background-image: radial-gradient(
    circle at 10px 26px,
    #f3f5f7 20px,
    transparent 21px
  );
  content: "";
  background-size: 40px 40px;
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  align-items: center;

  .none{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    margin-top: 2rem;

    h1{
      width: 100%;
      margin-top: 12px;
      max-width: 240px;
      color: rgb(48, 51, 60);
      font-size: 20px;
      font-weight: bold;
      line-height: 140%;
      text-align: center;
    }

    p{
      width: 100%;
      margin-top: 12px;
      max-width: 240px;
      color: rgb(127, 143, 159);
      font-size: 12px;
      font-weight: 500;
      line-height: 150%;
      text-align: center;
    }
  }

  section {
    max-width: 100%;
    width: 100%;

    h1 {
      font-family: Roboto, sans-serif;
      font-size: 14px;
      font-weight: bold;
      line-height: 150%;
      color: #30333c;
      display: flex;
      background-color: #fff;
      align-items: center;
    }

    p {
      font-family: Roboto, sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 150%;
      color: #30333c;
      margin: 10px 5px;
      display: flex;
      align-items: center;
    }
  }
`;

export const RefContainer = styled.div<RefProps>`
  width: 100%;
  max-width: 1024px;
  margin-bottom: 1rem;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  display: flex;
  flex-direction: row;
  justify-content: initial;
  align-items: center;
  background-color: #fff;
  z-index: 5;
  position: relative;
  padding: 20px;
  padding-top: 20px;
  padding-bottom: 20px;

  @media only screen and (min-width: 992px) and (max-width:1398px ){
    max-width: 1024px;
  }

  @media only screen and (min-width: 1400px){
    max-width: 1306px;
  }

  @media only screen and (max-width: 769px){
    padding-left: 15px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    padding-top: 10px;
  }

  &.minimized {
    position: fixed;
    top: ${props => props.header - 1}px;
  }

  .owl-prev{
    position: absolute;
    background: #fff !important;
    border: 1px solid #c4c4c4 !important;
    border-radius: 100%;
    z-index: 6 !important;
    width: 39px;
    height: 39px;
    left: -42px;
    top: 41px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 769px){
      display: none;
    }
    
    span{
      font-size: 30px;
      font-weight: 100;
      color: #333;
      height: 37px;
    }
  }

  .owl-next{
    position: absolute;
    background: #fff !important;
    border: 1px solid #c4c4c4 !important;
    border-radius: 100%;
    z-index: 6 !important;
    width: 39px;
    height: 39px;
    right: -41px;
    top: 41px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 769px){
      display: none;
    }
    
    span{
      font-size: 30px;
      font-weight: 100;
      color: #333;
      height: 37px;
    }
  }
`;
export const RefItem = styled.a`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  margin-right: 20px;
  max-width: 97px;

  &:hover{
    .itemIcon{
      transform: scale(1.05);
    }

    span{
      color: #3f3e3e;
    }
  }

  .itemIcon{
    transition: 0.3s;
    height: 80px;
    display: flex;
    align-items: flex-end;

    .retangulo{
      height: 40px;
      background-color: #1e90ff;
      width: 99px;
      border-radius: 7px;
    }

    img{
      object-fit: contain;
      height: 68px;
      width: 62px;
      display: block;
      margin: 0px auto;
      margin-top: -30px;
    }
  }

  span{
    transition: color .2s ease-out;
    margin: 5px 0 0;
    color: #717171;
    white-space: normal;
    text-align: center;
    font-size: .875rem;
    line-height: .880rem;
    min-height: 28px;
    padding-top: 5px;
  }
`;

export const Products = styled.div`
  padding-bottom: 4rem;
  max-width: 1024px;
  width: 100%;

  @media only screen and (min-width: 992px) and (max-width:1398px ){
    max-width: 1024px;
  }

  @media only screen and (min-width: 1400px){
    max-width: 1306px;
  }

  .top_section {
    right: 0px;
    bottom: 0px;
    left: 0px;
    height: 15px;
    background-image: radial-gradient(
      circle at 10px 26px,
      #f3f5f7 20px,
      transparent 21px
    );
    content: "";
    background-size: 40px 40px;
    margin-bottom: -5px;
  }

  .info {
    background: #f3f5f7;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    div {
      width: 90%;
      padding: 10px;
      border: 1px dashed #30333c;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        margin-left: 6px;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        color: #30333c;
      }
    }
  }

  section {
    h1 {
      background-color: green;
      height: 60px;
      margin-bottom: 0px;
      padding: 24px 16px 16px;
      border-bottom: 1px solid rgb(232, 234, 237);
      font-size: 14px;
      font-weight: bold;
      word-break: break-word;
      letter-spacing: 0.01em;
      text-transform: capitalize;
      color: #485460;
      background: #fff;
    }

    .listProduts{
      display: flex;
      align-items: center;
      columns: 3;
      flex-wrap: wrap;
      margin-bottom: 20px;

      @media only screen and (max-width: 769px){
        columns: 1;
      }
    }

    .item {
      height: 224px;
    border: 1px solid #f2f2f2;
    box-shadow: 0 1px 4px rgb(0 0 0 / 5%);
    border-radius: 4px;
    padding: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    min-width: 320px;
    position: relative;
    width: 49%;
    background: #fff;
    text-decoration: none;
    transition: .2s;
    margin-right: 1%;
    margin-top: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    
    @media only screen and (max-width: 769px){
      width: 100%;
      height: 159px;
    padding: 12px;
    }
    
      &:nth-child(2n){
        margin-right: 0px;
        margin-left: 1%;
      }
    
      &:hover {
        border: 1px solid #dbdad9;
      }

      .imageProduct{
        position: relative;
        width: 100%;
        max-width: 40%;

        img {
          width: 170px;
          height: 170px;
          /* width: 100%; */
          border-radius: 4px;
          object-fit: cover;
          object-position: center;
          float: right;

          @media only screen and (max-width: 769px){
            height: 132px;
            max-width: 127px;
          }
        }
      }

      .productInfo{
        display: grid;
        grid-area: info;
        grid-template-rows: 1fr;
        max-width: 65%;
        width: 100%;

        &.w-100_wImg{
          max-width: 100%;
        }

        .infosTop{
          margin-bottom: 23px;
          height: 149px;

          @media only screen and (max-width: 769px){
            height: 78px;
          }

          h2{
            display: grid;
            color: #3e3e3e;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.25rem;
            margin-top: 0;
            font-size: 1.125rem;
            line-height: 1.5rem;
            margin-bottom: 9px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 2;
          }

          p{
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            font-weight: lighter;
            color: #717171;
            word-break: break-word;
            margin-bottom: 10px;
            font-size: .875rem;
            line-height: 1.25rem;
            margin-bottom: 10px;
          }

          .serve{
            font-size: .875rem;
            line-height: 1rem;
            color: #3e3e3e;
            font-weight: 700;
            padding: 10px 0;
          }
        }

        .price{
          font-size: 1rem;
          line-height: 1.25rem;
          font-weight: 400;
          color: #3e3e3e;

          span{
            color: #717171;
          }

          &.pricepromocao{
            color: #50a773;

            .valorwhitoutpromotion{
              color: #717171;
              text-decoration: line-through;
              margin-left: 11px;
            }
          }
        }
      }
    }
  }
`;