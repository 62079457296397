import React, { useState, useEffect, useCallback } from "react";
import { FiArrowLeft, FiPlus, FiMinus, FiCheck, FiX } from "react-icons/fi";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { format, parse } from "date-fns";
import { toast } from "react-toastify";
/* Context */
import { useMenu } from "../../context/MenuContext";
/* Services */
import Services from "../../services/Services";
/* Styles */
import {
  Container,
  Header,
  Content,
  Footer,
  Nav,
  Required,
  Radio,
  RadioActive,
  Checkbox,
} from "./styles";
import Load from "../../components/Load";

const DetailPage: React.FC = () => {
  const history = useHistory();
  const location = useLocation<any>();
  const params = useParams<any>();
  const { addCart, data } = useMenu();
  const [loading, setLoading] = useState<any>(true);
  const [dt, setData] = useState<any>({});
  const [productDetail, setProductDetail] = useState<any>([]);

  const [total, setTotal] = useState<number>(0);
  const [totalUnity, setTotalUnity] = useState<number>(0);
  const [count, setCount] = useState(1);
  const [disable, setDisable] = useState<boolean>(true);
  const [opened, setOpened] = useState(false);
  const [length, setLength] = useState<any>(undefined);
  const [lengthObject, setLengthObject] = useState<any>({});
  const [flavors, setFlavors] = useState<any>([]);
  const [flavorsFilters, setFlavorsFilters] = useState<any>([]);
  const [flavorsSelected, setFlavorsSelected] = useState<any>([]);
  const [openModal, setOpenModal] = useState<any>(false);
  const [gpAdicionais, setGpAdicionais] = useState<any>([]);
  const [gpTamanhos, setGpTamanhos] = useState<any>([]);
  const [observacaoItem, setObservacaoItem] = useState<any>("");

  useEffect(() => {
    if (params.empresa !== data.empresa) {
      history.push(
        `/${params.empresa}/detail/${params.id_group}/${params.product}`
      );
    }

    // setTotal(location?.state?.detail?.valor)
    // setTotalUnity(location?.state?.detail?.valor)
    // setData(location?.state?.detail)

    let storage: any = localStorage.getItem("@menu-digital:data");
    getOpened(JSON.parse(storage));
    setLoading(true);

    if (params.product != undefined) {
      const fetchData = async () => {
        await Services.detail_product(
          params?.empresa,
          params.id_group,
          params.product
        )
          .then((response) => {
            if (response.data.retorno) {
              history.push({
                pathname: "/",
                state: {
                  message: response.data.retorno,
                  empresa: params?.empresa,
                },
              });
            }

            setData(response.data[0]);

            var dtFinal = [];

            for (let i = 0; i < response.data.length; i++) {
              var itensDetalhe = response.data[i];

              for (
                let x = 0;
                x < itensDetalhe[Object.keys(itensDetalhe)[0]].length;
                x++
              ) {
                var linha = itensDetalhe[Object.keys(itensDetalhe)[0]][x];

                linha.selected_index = [];
              }

              dtFinal.push(itensDetalhe);
            }

            var gpArrayAdicionais = dtFinal.filter((entry) => {
              return entry.grupo_adicional;
            });

            if (gpArrayAdicionais.length > 0) {
              setGpAdicionais(gpArrayAdicionais[0].grupo_adicional);
            }

            // if (dtFinal.length > 0) {
            //   setGpAdicionais(dtFinal)
            // }

            var gpArrayTamanhos = dtFinal.filter((entry) => {
              return entry.tamanhos;
            });

            if (gpArrayTamanhos.length > 0) {
              setGpTamanhos(gpArrayTamanhos[0].tamanhos);
            }

            dtFinal = dtFinal.filter(
              (entry) => entry.grupo_adicional || entry.tamanhos
            );

            setProductDetail(dtFinal);

            setLoading(false);

            window.scrollTo(0, 0);
          })
          .catch((error) => {
            setLoading(false);
          });
      };

      fetchData().catch(console.error);
    }
  }, [location, params, history, data]);

  const getTotal = useCallback(() => {
    let v: number =
      dt.em_promocao == true
        ? dt.valor_promocao !== undefined
          ? dt.valor_promocao
          : 0
        : dt.valor !== undefined
        ? dt.valor
        : 0;
    let storage: any = localStorage.getItem("@menu-digital:data");
    storage = JSON.parse(storage);

    if (
      storage.regra_valor_montagem &&
      storage.regra_valor_montagem === "MAIOR"
    ) {
      if (gpTamanhos !== undefined) {
        if (lengthObject.valor > v) {
          v = lengthObject.valor;
        }

        if (flavorsSelected.length > 0) {
          if (flavorsSelected)
            flavorsSelected.forEach((element: any) => {
              if (element.valor > v) {
                v = element.valor;
              }
            });
        }
      }
    }

    if (
      storage.regra_valor_montagem &&
      storage.regra_valor_montagem === "MEDIA"
    ) {
      if (gpTamanhos !== undefined) {
        let sum = lengthObject.valor;

        if (flavorsSelected.length > 0) {
          flavorsSelected.forEach((element: any) => {
            sum = sum + element.valor;
          });
        }

        v = v + sum / (flavorsSelected.length + 1);
      }
    }

    if (productDetail.length > 0) {
      productDetail.forEach((element: any) => {
        var elementTwo = element[Object.keys(element)[0]];

        elementTwo.forEach((item: any) => {
          item.selected_index.forEach((row: any) => {
            v = v + row.valor;
          });
        });
      });
    }
    setTotalUnity(v);
    setTotal(v * count);
  }, [productDetail, count, lengthObject, flavorsSelected]);

  const handleLength = async (tam: any) => {
    await Services.flavors(dt.id_grupo, tam, params?.empresa).then(
      (response) => {
        console.debug("dt", dt);

        let find = response.data.filter(
          (filter: any) => filter.id_sabor == dt.id_produto
        );

        if (find.length > 0) {
          let flavs = find;
          setFlavorsSelected(flavs);
        }

        setFlavors(response.data);
        setFlavorsFilters(response.data);
      }
    );
  };

  const getDisable = useCallback(() => {
    let cont = 0;
    if (productDetail.length > 0) {
      if (gpAdicionais.length > 0) {
        gpAdicionais.forEach((element: any) => {
          if (element.selected_index.length < element.Qtd_obg) {
            cont = cont + 1;
          }
        });
      }
    }

    if (cont === 0) {
      if (gpTamanhos.length > 0 && length === undefined) {
        setDisable(true);
      } else {
        setDisable(false);
      }
    } else {
      setDisable(true);
    }
  }, [productDetail, dt, length]);

  useEffect(() => {
    getTotal();
    getDisable();
  }, [getTotal, productDetail, getDisable]);

  const handleItemOrder = () => {
    if (window.confirm("Deseja realmente adicionar no carrinho?")) {
      let product: any = {
        id: dt.id_produto,
        descricao: dt.descricao,
        unidade: dt.unidade,
        tipo: dt.tipo,
        editado: dt.custom,
        quantidade: count,
        tag: dt.tag ? dt.tag : dt.tag,
        valor:
          dt.em_promocao == false || dt.em_promocao == undefined
            ? dt.valor !== undefined
              ? dt.valor
              : 0
            : dt.valor_promocao !== undefined
            ? dt.valor_promocao
            : 0,
        valor_total: totalUnity,
        observacao_item: observacaoItem,
      };

      if (gpAdicionais.length > 0) {
        let adc: any = [];

        var tAdicional = 0;

        gpAdicionais.forEach((element: any) => {
          element.selected_index.forEach((item: any) => {
            tAdicional = item.valor * count + tAdicional;

            adc.push({
              ...item,
              quantidade: count,
            });
          });
        });

        product.total_adicional = tAdicional;
        product.adicional = adc;
      }

      if (flavorsSelected.length > 0) {
        product.sabores = flavorsSelected;
      }

      if (lengthObject != undefined) {
        product.tamanho = lengthObject;
      }

      addCart(product);

      history.push(`/${data.empresa}/menu`);
    }
  };

  const getOpened = (dt: any) => {
    const day = format(new Date(), "i");
    const now = new Date();
    const openingTime = parse(dt.hora_abre, "HH:mm", new Date());
    const closingTime = parse(dt.hora_fecha, "HH:mm", new Date());

    // Verifica se o estabelecimento funciona durante a noite (fechamento maior que abertura)
    const isOpenDuringNight = closingTime < openingTime;

    // Define os horários de abertura e fechamento corretos, levando em conta a possibilidade de funcionamento durante a noite
    const open = openingTime;
    const close = closingTime;

    let isCurrentlyOpen;
    // Verifica se o horário atual está dentro do intervalo de funcionamento
    if (isOpenDuringNight) {
      isCurrentlyOpen =
        (now >= open || now <= close) &&
        now >= parse("00:00", "HH:mm", new Date()) &&
        now <= parse("23:59", "HH:mm", new Date());
    } else {
      isCurrentlyOpen = now >= open && now <= close;
    }

    // Verifica se o estabelecimento está aberto no dia da semana atual
    const isTodayOpen =
      (day === "1" && dt.abre_segunda) ||
      (day === "2" && dt.abre_terca) ||
      (day === "3" && dt.abre_quarta) ||
      (day === "4" && dt.abre_quinta) ||
      (day === "5" && dt.abre_sexta) ||
      (day === "6" && dt.abre_sabado) ||
      (day === "7" && dt.abre_domingo);

    // Define a variável opened com base nas condições de funcionamento
    setOpened(isCurrentlyOpen && isTodayOpen);

    // Verifica se o horário atual está fora do intervalo de funcionamento e é depois das 22:00 ou antes das 08:00
    if (!isCurrentlyOpen && (now.getHours() < 8 || now.getHours() >= 22)) {
      setOpened(false);
    }
  };

  const money = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  });

  if (loading) {
    return <Load dtcolor={data?.primary_color} />;
  }

  const filterPromotionTamanhoArrayMenor = (tamanho: any) => {
    let arrayValores = [];

    if (tamanho.valor_g_promocao != undefined) {
      arrayValores.push(tamanho.valor_g_promocao);
    } else {
      if (tamanho.valor_g != undefined) {
        arrayValores.push(tamanho.valor_g);
      }
    }

    if (tamanho.valor_m_promocao != undefined) {
      arrayValores.push(tamanho.valor_m_promocao);
    } else {
      if (tamanho.valor_m != undefined) {
        arrayValores.push(tamanho.valor_m);
      }
    }

    if (tamanho.valor_p_promocao != undefined) {
      arrayValores.push(tamanho.valor_p_promocao);
    } else {
      if (tamanho.valor_p != undefined) {
        arrayValores.push(tamanho.valor_p);
      }
    }

    return Math.min(...arrayValores);
  };

  const filterPromotionTamanhoArrayMaior = (tamanho: any) => {
    let arrayValores = [];

    if (tamanho.valor_g_promocao != undefined) {
      arrayValores.push(tamanho.valor_g_promocao);
    } else {
      if (tamanho.valor_g != undefined) {
        arrayValores.push(tamanho.valor_g);
      }
    }

    if (tamanho.valor_m_promocao != undefined) {
      arrayValores.push(tamanho.valor_m_promocao);
    } else {
      if (tamanho.valor_m != undefined) {
        arrayValores.push(tamanho.valor_m);
      }
    }

    if (tamanho.valor_p_promocao != undefined) {
      arrayValores.push(tamanho.valor_p_promocao);
    } else {
      if (tamanho.valor_p != undefined) {
        arrayValores.push(tamanho.valor_p);
      }
    }

    return Math.max(...arrayValores);
  };

  const handleFilterProducts = (e: any) => {
    const searchString = e.target.value.toLowerCase();

    const filteredFlavors = flavors.filter((flavor: any) => {
      const flavorDescricao = flavor.descricao.toLowerCase();
      return flavorDescricao.includes(searchString);
    });

    setFlavorsFilters(filteredFlavors);
  };

  return (
    <Container>
      <Header>
        <Nav>
          <FiArrowLeft
            cursor="pointer"
            size={20}
            onClick={() => history.goBack()}
          />
          <h1>Detalhe do produto</h1>
          <div />
        </Nav>
        <h2>{dt.descricao}</h2>
        <p>{dt.detalhe}</p>
        {dt.foto_destaque !== undefined && (
          <div className="container_image">
            <img src={`${dt.foto_destaque}`} alt={dt.descricao} />
          </div>
        )}
        <div className="row">
          {/* <h2>A partir de {dt.valor !== undefined ? money.format(dt.valor) : "R$0,00"}</h2> */}
          <h2 className={`${dt?.em_promocao == true ? "pricepromocao" : ""}`}>
            {dt.tamanhos && <span>De: </span>}

            {["P", "O"].indexOf(dt.tipo) > -1 && (
              <div>
                {money.format(dt.valor_de)} <span>até: </span>
                {/* {dt?.em_promocao == false ? money.format(dt?.valor_de) : (filterPromotionTamanho(dt) < dt?.valor_de ? money.format(filterPromotionTamanho(dt)) : money.format(dt?.valor_de))} <span>até: </span> */}
                {money.format(dt.valor_ate)}
              </div>
            )}
            {["P", "O"].indexOf(dt.tipo) === -1 &&
              (dt?.em_promocao == false || dt?.em_promocao == undefined
                ? money.format(dt?.valor)
                : money.format(dt?.valor_promocao))}
            {["P", "O"].indexOf(dt.tipo) === -1 && dt?.em_promocao == true ? (
              <span className="valorwhitoutpromotion">
                {dt?.em_promocao == true && money.format(dt?.valor)}
              </span>
            ) : (
              ""
            )}
          </h2>

          {dt.tag && <div className="tag">{dt.tag}</div>}
        </div>
      </Header>

      <Content>
        {gpTamanhos.length > 0 && (
          <section>
            <div className="column">
              <h2>TAMANHO</h2>
              <Required>Obrigatório</Required>
            </div>
            {gpTamanhos.map((tam: any, index: number) => (
              <article key={uuidv4()}>
                <label htmlFor={tam?.tamanho}>
                  {tam?.tamanho}{" "}
                  <span style={{ marginLeft: -10 }}>
                    (Até {tam?.qtd_sabor} sabores)
                  </span>{" "}
                </label>
                <div className="content">
                  <strong>{money.format(tam?.valor)}</strong>
                  <Radio
                    onClick={() => {
                      if (tam.tag !== lengthObject.tag) {
                        setFlavorsSelected([]);
                        handleLength(tam?.tag);
                      }
                      setLength(index);
                      setLengthObject(tam);
                      setOpenModal(true);
                    }}
                  >
                    {index === length && (
                      <RadioActive
                        style={{ background: data?.primary_color }}
                      />
                    )}
                  </Radio>
                </div>
              </article>
            ))}
          </section>
        )}
        {openModal && (
          <div className="modal">
            <div className="modal__content">
              <div
                onClick={() => setOpenModal(false)}
                className="modal__content__close"
                style={{ background: data?.primary_color }}
              >
                <FiX color="#fff" size={40} />
              </div>
              <div className="modal_header">
                <h1>Sabores</h1>
              </div>
              <div
                className="modal_content"
                style={{ maxHeight: "83vh", overflowY: "auto" }}
              >
                {length !== undefined && (
                  <div className="saboresBox" style={{ width: "100%" }}>
                    <section style={{ position: "relative", width: "100%" }}>
                      <div className="column">
                        <div style={{ width: "100%" }}>
                          <h2>
                            Escolha até {lengthObject.qtd_sabor} opç
                            {lengthObject.qtd_sabor > 1 ? "ões" : "ão"}
                          </h2>
                          <div className="form_input" style={{ width: "100%" }}>
                            <input
                              name="name"
                              type="search"
                              className="input"
                              onChange={(e) => handleFilterProducts(e)}
                              onKeyPress={(e) => handleFilterProducts(e)}
                              placeholder="Digite para filtrar por um sabor específico"
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        className="saboresList"
                        style={{ maxHeight: "50vh", overflowY: "auto" }}
                      >
                        {flavorsFilters.map((sab: any, index: number) => (
                          <article key={uuidv4()}>
                            <label htmlFor={sab?.descricao}>
                              {sab?.descricao}
                            </label>
                            <div className="content">
                              <strong>{money.format(sab?.valor)}</strong>
                              <Checkbox
                                color={
                                  data.primary_color != undefined
                                    ? data.primary_color
                                    : "#1e90ff"
                                }
                                checked={
                                  flavorsSelected.filter(
                                    (filter: any) =>
                                      filter.id_sabor == sab.id_sabor
                                  ).length > 0
                                    ? true
                                    : false
                                }
                                onClick={(e: any) => {
                                  let dt = flavorsSelected;
                                  if (lengthObject.qtd_sabor === 0) {
                                    if (
                                      flavorsSelected.find(
                                        (filter: any) => filter === sab
                                      ) !== undefined
                                    ) {
                                      if (sab.descricao !== dt.descricao) {
                                        let ind = dt.indexOf(sab);
                                        dt.splice(ind, 1);
                                      }
                                    } else {
                                      dt.push(sab);
                                    }
                                  } else {
                                    if (dt.length < lengthObject.qtd_sabor) {
                                      if (
                                        flavorsSelected.find(
                                          (filter: any) => filter === sab
                                        ) !== undefined
                                      ) {
                                        if (sab.descricao !== dt.descricao) {
                                          let ind = dt.indexOf(sab);
                                          dt.splice(ind, 1);
                                        }
                                      } else {
                                        dt.push(sab);
                                      }
                                    } else {
                                      if (e.target.checked != undefined)
                                        toast(
                                          `Selecione até ${
                                            lengthObject.qtd_sabor
                                          } opç${
                                            lengthObject.qtd_sabor > 1
                                              ? "ões"
                                              : "ão"
                                          }`,
                                          { type: "warning" }
                                        );
                                      if (
                                        flavorsSelected.find(
                                          (filter: any) => filter === sab
                                        ) !== undefined
                                      ) {
                                        if (sab.descricao !== dt.descricao) {
                                          let ind = dt.indexOf(sab);
                                          dt.splice(ind, 1);
                                        }
                                      }
                                    }
                                  }

                                  setFlavorsSelected([...dt]);
                                }}
                              >
                                {flavorsSelected.find(
                                  (filter: any) =>
                                    filter.id_sabor === sab.id_sabor
                                ) !== undefined ? (
                                  <FiCheck color="#fff" size={20} />
                                ) : null}
                              </Checkbox>
                            </div>
                          </article>
                        ))}
                      </div>
                    </section>

                    <div className="buttonSection" style={{ paddingTop: 20 }}>
                      <button
                        onClick={() => {
                          setOpenModal(false);
                          window.scrollTo(0, 0);
                        }}
                        style={{ backgroundColor: data?.primary_color }}
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {flavorsSelected && flavorsSelected.length ? (
          <section>
            <div className="column">
              <div>
                <h2>SABORES ESCOLHIDOS</h2>
                {flavorsSelected?.map((sab: any, index: number) => (
                  <div className="content" key={uuidv4()}>
                    <label
                      htmlFor="sab?.descricao"
                      style={{ fontSize: "12px" }}
                    >
                      1/{flavorsSelected.length} {sab?.descricao}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </section>
        ) : (
          ""
        )}

        {gpAdicionais !== undefined &&
          gpAdicionais.map((item: any, index: any) => (
            <section key={item.id}>
              <div className="column">
                <h2>{item.descricao}</h2>
                {item.Qtd_obg >= 1 && <Required>Obrigatório</Required>}
              </div>
              {item.Qtd_obg >= 1 && (
                <span>Escolha {item.Qtd_maximo} opções</span>
              )}
              {item.Qtd_maximo > 1 && item.Qtd_obg === 0 ? (
                <span>Escolha até {item.Qtd_maximo} opções</span>
              ) : null}

              {item.adicionais !== undefined &&
                item.adicionais.map((add: any) =>
                  item.Qtd_maximo === 1 ? (
                    <article key={uuidv4()}>
                      <label htmlFor={add?.descricao}>{add?.descricao}</label>
                      <div className="content">
                        <strong>{money.format(add?.valor)}</strong>
                        <Radio
                          onClick={() => {
                            let dt = productDetail;
                            var gp = dt.filter((entry: any) => {
                              return entry.grupo_adicional ? true : false;
                            });

                            gp[0].grupo_adicional[index].selected_index = [add];
                            setProductDetail([...dt]);
                          }}
                        >
                          {item.selected_index.filter(
                            (filter: any) => filter.id === add.id
                          ).length > 0 ? (
                            <RadioActive
                              color={
                                data.primary_color != undefined
                                  ? data.primary_color
                                  : "#1e90ff"
                              }
                            />
                          ) : null}
                        </Radio>
                      </div>
                    </article>
                  ) : null
                )}

              {item.adicionais !== undefined &&
                item.adicionais.map((add: any) =>
                  item.Qtd_maximo > 1 ? (
                    <article key={uuidv4()}>
                      <label htmlFor={add?.descricao}>{add?.descricao}</label>
                      <div className="content">
                        <strong>{money.format(add?.valor)}</strong>
                        <Checkbox
                          color={data?.primary_color}
                          checked={
                            item.selected_index.find(
                              (filter: any) => filter === add
                            ) !== undefined
                              ? true
                              : false
                          }
                          onClick={() => {
                            let dt = productDetail;
                            if (item.Qtd_maximo === 0) {
                              if (
                                item.selected_index.find(
                                  (filter: any) => filter === add
                                ) !== undefined
                              ) {
                                let ind = dt[index].selected_index.indexOf(add);
                                dt[index].selected_index.splice(ind, 1);
                              } else {
                                dt[index].selected_index.push(add);
                              }
                            } else {
                              if (
                                item.selected_index.length < item.Qtd_maximo
                              ) {
                                if (
                                  item.selected_index.find(
                                    (filter: any) => filter === add
                                  ) !== undefined
                                ) {
                                  let ind = item.selected_index.indexOf(add);
                                  item.selected_index.splice(ind, 1);
                                } else {
                                  item.selected_index.push(add);
                                }
                              } else {
                                toast(
                                  `Selecione até ${item.Qtd_maximo} opções`,
                                  { type: "warning" }
                                );
                                if (
                                  item.selected_index.find(
                                    (filter: any) => filter === add
                                  ) !== undefined
                                ) {
                                  let ind = item.selected_index.indexOf(add);
                                  item.selected_index.splice(ind, 1);
                                }
                              }
                            }

                            setProductDetail([...dt]);
                          }}
                        >
                          {item.selected_index.find(
                            (filter: any) => filter === add
                          ) !== undefined ? (
                            <FiCheck color="#fff" size={20} />
                          ) : null}
                        </Checkbox>
                      </div>
                    </article>
                  ) : null
                )}

              {item.adicionais !== undefined &&
                item.adicionais.map((add: any) =>
                  item.Qtd_obg === 0 && item.Qtd_maximo === 0 ? (
                    <article key={uuidv4()}>
                      <label htmlFor={add?.descricao}>{add?.descricao}</label>
                      <div className="content">
                        <strong>{money.format(add?.valor)}</strong>
                        <Checkbox
                          color={data?.primary_color}
                          checked={
                            item.selected_index.find(
                              (filter: any) => filter === add
                            ) !== undefined
                              ? true
                              : false
                          }
                          onClick={() => {
                            let dt = productDetail;
                            if (item.Qtd_maximo === 0) {
                              if (
                                item.selected_index.find(
                                  (filter: any) => filter === add
                                ) !== undefined
                              ) {
                                let ind = item.selected_index.indexOf(add);
                                item.selected_index.splice(ind, 1);
                              } else {
                                item.selected_index.push(add);
                              }
                            } else {
                              if (
                                dt[index].selected_index.length <
                                item.Qtd_maximo
                              ) {
                                if (
                                  item.selected_index.find(
                                    (filter: any) => filter === add
                                  ) !== undefined
                                ) {
                                  let ind =
                                    dt[index].selected_index.indexOf(add);
                                  dt[index].selected_index.splice(ind, 1);
                                } else {
                                  dt[index].selected_index.push(add);
                                }
                              } else {
                                if (
                                  item.selected_index.find(
                                    (filter: any) => filter === add
                                  ) !== undefined
                                ) {
                                  let ind =
                                    dt[index].selected_index.indexOf(add);
                                  dt[index].selected_index.splice(ind, 1);
                                }
                              }
                            }

                            setProductDetail([...dt]);
                          }}
                        >
                          {item.selected_index.find(
                            (filter: any) => filter === add
                          ) !== undefined ? (
                            <FiCheck color="#fff" size={20} />
                          ) : null}
                        </Checkbox>
                      </div>
                    </article>
                  ) : null
                )}

              {item.adicionais !== undefined &&
                item.adicionais.map(
                  (add: any) =>
                    item.Qtd_obg >= 2 && (
                      <article key={uuidv4()}>
                        <label htmlFor={add?.descricao}>{add?.descricao}</label>
                        <div className="content">
                          <strong>{money.format(add?.valor)}</strong>
                          <Checkbox
                            color={data?.primary_color}
                            checked={
                              item.selected_index.find(
                                (filter: any) => filter === add
                              ) !== undefined
                                ? true
                                : false
                            }
                            onClick={() => {
                              let dt = productDetail;
                              if (item.Qtd_maximo === 0) {
                                if (
                                  item.selected_index.find(
                                    (filter: any) => filter === add
                                  ) !== undefined
                                ) {
                                  let ind =
                                    dt[index].selected_index.indexOf(add);
                                  dt[index].selected_index.splice(ind, 1);
                                } else {
                                  dt[index].selected_index.push(add);
                                }
                              } else {
                                if (
                                  dt[index].selected_index.length <
                                  item.Qtd_maximo
                                ) {
                                  if (
                                    item.selected_index.find(
                                      (filter: any) => filter === add
                                    ) !== undefined
                                  ) {
                                    let ind =
                                      dt[index].selected_index.indexOf(add);
                                    dt[index].selected_index.splice(ind, 1);
                                  } else {
                                    dt[index].selected_index.push(add);
                                  }
                                } else {
                                  toast(`Selecione ${item.Qtd_maximo} itens`, {
                                    type: "warning",
                                  });
                                  if (
                                    item.selected_index.find(
                                      (filter: any) => filter === add
                                    ) !== undefined
                                  ) {
                                    let ind =
                                      dt[index].selected_index.indexOf(add);
                                    dt[index].selected_index.splice(ind, 1);
                                  }
                                }
                              }

                              setProductDetail([...dt]);
                            }}
                          >
                            {item.selected_index.find(
                              (filter: any) => filter === add
                            ) !== undefined ? (
                              <FiCheck color="#fff" size={20} />
                            ) : null}
                          </Checkbox>
                        </div>
                      </article>
                    )
                )}
            </section>
          ))}

        {/* <section>
            <div className="column">
              <h2>o que vai dentro do seu açaí?</h2>
              <div />
            </div>
            <span>Escolha até duas opções</span>
            <article>
              <label htmlFor="Creme de cupuaçu">Creme de cupuaçu</label>
              <div className="content">
                <Count>
                  <button>
                    <FiMinus />
                  </button>
                  0
                  <button>
                    <FiPlus />
                  </button>
                </Count>
              </div>
            </article>
            <article>
              <label htmlFor="Creme de cupuaçu">Creme de cupuaçu</label>
              <div className="content">
                <Count>
                  <button>
                    <FiMinus />
                  </button>
                  0
                  <button>
                    <FiPlus />
                  </button>
                </Count>
              </div>
            </article>
          </section> */}

        <section>
          <div className="column">
            <h2>Observações</h2>
            <div />
          </div>
          <textarea
            onChange={(e) => setObservacaoItem(e.target.value)}
            value={observacaoItem}
          ></textarea>

          <p>
            Converse diretamente com o estabelecimento caso queira modificar
            algum item. Neste campo não são aceitas modificações que podem gerar
            cobrança adicional.
          </p>
        </section>
      </Content>

      <Footer colour={data?.primary_color ? data?.primary_color : "#1e90ff"}>
        {opened ? (
          <>
            <div className="btn_container">
              <button
                onClick={() => {
                  if (count > 1) {
                    setCount(count - 1);
                    setTotal(totalUnity * (count - 1));
                  }
                }}
              >
                <FiMinus />
              </button>
              {count}
              <button
                onClick={() => {
                  setCount(count + 1);
                  setTotal(totalUnity * (count + 1));
                }}
              >
                <FiPlus />
              </button>
            </div>
            <div
              className="btn"
              style={{
                background: disable
                  ? "#485460"
                  : data?.primary_color
                  ? data?.primary_color
                  : "#1e90ff",
              }}
              onClick={() =>
                disable
                  ? toast("Preencha todos os campos obrigatórios", {
                      type: "warning",
                    })
                  : handleItemOrder()
              }
            >
              <p
                style={{
                  borderRight: "1px solid #fff",
                  paddingRight: 20,
                }}
              >
                Adicionar
              </p>
              <p>{money.format(total)}</p>
            </div>
          </>
        ) : (
          <div className="close">
            <h2>Fechado no momento!</h2>
            <p>No momento, não estamos aceitando novos pedidos.</p>
            <button>Adicionar</button>
          </div>
        )}
      </Footer>
    </Container>
  );
};

export default DetailPage;
