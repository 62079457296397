import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #f3f5f7;
`;
export const Header = styled.header`
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;

  h2{
    font-size: 16px;
    font-weight: bold;
    line-height: 120%;
    padding: 0.5rem 1rem;
  }

  .row{
    margin-top:10px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
`;

export const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 4rem;
  border-bottom: 1px solid rgb(232, 234, 237);

  padding: 1rem;

  h1{
    margin-right: 35px;
    font-size: 15px;
    line-height: 150%;
    font-weight: 500;
    text-align: center;
    color: rgb(48, 51, 60);
  }
`;

export const Required = styled.div`
  padding: 4px 8px;
  margin-right: 0px;
  border-radius: 24px;
  opacity: 1;
  font-size: 12px;
  transition: all 0.3s ease 0s;
  font-weight: 600;
  text-align: center;
  visibility: visible;
  color: rgb(255, 255, 255);
  background: rgb(127, 143, 159);
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 1rem;
  padding-bottom: 8rem;

  section{
    width: 100%;
    .column{
      display: flex;
      align-items: center;
      justify-content: space-between;
      
      h2{
        font-size: 14px;
        font-weight: bold;
        color: #485460;
        margin: 1rem 0;
      }
    }

    span{
      padding: 1rem;
      margin-top: 2px;
      font-size: 12px;
      font-weight: 500;
      line-height: 150%;
      color: rgb(127, 143, 159);
    }

    p{
      width: 90%;
      margin: 8px auto 0px;
      font-size: 12px;
      line-height: 150%;
      font-weight: 500;
      color: rgb(127, 143, 159);
    }

    textarea{
      width: 90%;
      resize: none;
      outline: none;
      padding: 10px 16px;
      min-height: 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgb(232, 234, 237);
      padding: 10px;
      margin: 0 16px;
      border: none;
    }
    
    input{
      width: 100%;
      resize: none;
      outline: none;
      padding: 10px 16px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgb(232, 234, 237);
      border: none;
    }
    
    .container_select{
      width: 100%;
      resize: none;
      outline: none;
      padding: 10px 16px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgb(232, 234, 237);
      background-color: #fff;
      border: none;
    }

    .container_select3{
      width: 100%;
    }

    .react-select-container {
      border: none !important;
    }
    
    .react-select__control {
      border: none !important;
      box-shadow: none !important;
    }
    

    
    .container_select3 .slect{
      border: 0px !important;
      boxShadow: 'none' !important;
    }

    .container_select3 .slect .react-select__control {
      border: none !important;
      box-shadow: none !important;    
    }

    .container_select3 .slect div{
      resize: none;
      outline: none;
      border-bottom: 1px solid rgb(232,234,237);
      border: 0px !important;
    }

    .container_select3 .slect div input:after{
      border: 0px !important
    }

    .container_select3 span{
      display: none
    }

    select{
      width: 100%;
      border: none;
      background-color: #fff;
      outline: none;
    }

    article{
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgb(232, 234, 237);
      padding: 10px 4px 10px 0px;
      margin: 0 16px;
      label{
        font-size: 14px;
        line-height: 150%;
        font-weight: 500;
        color: #485460;
      }

      .content{
        display: flex;
        align-items: center;
        strong{
          margin-right: 12px;
          font-size: 14px;
          line-height: 150%;
          font-weight: 600;
          text-align: right;
          white-space: nowrap;
          color: rgb(72, 84, 96);
        }

      }
    }
  }

`;

export const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  background-color: #1e90ff;
  height: 4rem;
  width: 100%;
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  cursor: pointer;
  color: #fff;


  h1{
    font-size: 16px;
  }
`;
