import React, { useState, useEffect, ChangeEvent } from 'react';
import {
  FiArrowLeft,
  FiArrowRight,
  FiCheckCircle
} from "react-icons/fi";
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
/* Context */
import { useMenu } from '../../context/MenuContext'
/* Services */
import Services from '../../services/Services';
/* Styles */
import {
  Container,
  Header,
  Content,
  Footer,
  Nav,
  Required,
} from './styles';
import Select from 'react-select';

type SelectOptionType = { label: string, value: string, valuetaxa: string }

const OrderPage: React.FC = () => {
  const history = useHistory()
  const params = useParams<any>()
  const { updateOrder, data, addAddress } = useMenu()
  /* Address */
  const [districts, setDistricts] = useState<any>([])
  const [bairros, setBairros] = useState<any>([])
  const [districtId, setDistrictId] = useState<number | undefined>()
  const [district, setDistrict] = useState<string>("")
  const [address, setAddress] = useState<string>("")
  const [number, setNumber] = useState<string>("")
  const [complement, setComplement] = useState<string>("")
  const [referencePoint, setReferencePoint] = useState<string>("")

  const [taxa, setTaxa] = useState<number>(0)

  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    (async () => {
      if (params.empresa !== data.empresa) {
        history.push(`/${params.empresa}`)
      }

      await Services.districts(params.empresa)
        .then(response => {
          setDistricts(response.data)
          setLoading(false)
        }).catch(() => {
          setLoading(false)
        })
    })()
  }, [history, params, data])

  const money = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  });


  useEffect(() => {
    var arrayFinal: any[];
    arrayFinal = [];

    for (let i = 0; i < districts.length; i++) {
      const element = districts[i];

      arrayFinal = [...arrayFinal, { label: `${element.bairro}`, value: element.id, valuetaxa: money.format(element.valor) }]
    }

    setBairros(arrayFinal);
  }, [districts]);


  const handleAddress = () => {
    updateOrder({
      logradouro: address,
      numero: number,
      complemento: complement,
      cidade: data.cidade,
      uf: data.estado,
      ponto_referencia: referencePoint,
      bairro: district,
      id_bairro: districtId,
      valor_taxa: taxa
    })
    addAddress({
      logradouro: address,
      numero: number,
      complemento: complement,
      cidade: data.cidade,
      uf: data.estado,
      ponto_referencia: referencePoint,
      bairro: district,
      id_bairro: districtId,
      valor_taxa: taxa
    })

    history.push(`/${data.empresa}/order`)
  }

  if (loading) {
    return <div />
  }

  const handleInputChange = (option: SelectOptionType | null) => {
    if (option) {
      let dt = districts.filter((filter: any) => filter.id === Number(option.value));

      if (dt.length > 0) {
        setDistrict(dt[0].bairro)
        setDistrictId(dt[0].id)
        setTaxa(dt[0].valor)
      }
    }
  };

  return (
    <Container>
      <Header>
        <Nav>
          <FiArrowLeft cursor="pointer" size={20} onClick={() => history.goBack()} />
          <h1>Endereço de entrega</h1>
          <div />
        </Nav>
      </Header>

      <Content>
        <section>
          <div className="column">
            <h2>Estado</h2>
          </div>
          <input
            name="state"
            type="text"
            value={data.estado}
            onChange={() => { }}
          />
        </section>

        <section>
          <div className="column">
            <h2>Cidade</h2>
          </div>
          <input
            name="city"
            type="text"
            value={data.cidade}
            onChange={() => { }}
          />
        </section>

        <section>
          <div className="column">
            <h2>Bairro</h2>
            {district.length === 0 ?
              <Required>Obrigatório</Required>
              :
              <FiCheckCircle color="#008000" size={20} />
            }
          </div>
          <div className="container_select3">
            <Select
              options={bairros}
              onChange={handleInputChange}
              placeholder="Selecione um bairro"
              className='slect'
              noOptionsMessage={() => "Não encontrado!"}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              formatOptionLabel={({ value, label, valuetaxa }) => (
                <div style={{ display: "flex", justifyContent: 'space-between' }}>
                  <div>{label}</div>
                  <div>
                    {valuetaxa}
                  </div>
                </div>
              )
              }
            />
            {/* <select
              name="district"
              onChange={(event) => {
                let dt = districts.filter((filter: any) => filter.id === Number(event.target.value))

                if (dt.length > 0) {
                  setDistrict(dt[0].bairro)
                  setDistrictId(dt[0].id)
                  setTaxa(dt[0].valor)
                }
              }}
            >
              <option value="" disabled selected>{"Selecione um bairro"}</option>
              {districts.map((element: any) => (
                <option
                  key={element.id}
                  value={element.id}
                >
                  {element.bairro} - Taxa de entega: {money.format(element.valor)}
                </option>
              ))}
            </select> */}
          </div>
        </section>

        <section>
          <div className="column">
            <h2>Endereço</h2>
            {address.length === 0 ?
              <Required>Obrigatório</Required>
              :
              <FiCheckCircle color="#008000" size={20} />
            }
          </div>
          <input name="address" type="text" value={address} onChange={(event) => setAddress(event.target.value)} />
        </section>

        <section>
          <div className="column">
            <h2>Número</h2>
          </div>
          <input name="number" type="text" value={number} onChange={(event) => setNumber(event.target.value)} />
        </section>

        <section>
          <div className="column">
            <h2>Complemento</h2>
          </div>
          <input name="complement" type="text" value={complement} onChange={(event) => setComplement(event.target.value)} />
        </section>

        <section>
          <div className="column">
            <h2>Ponto de Referência</h2>
          </div>
          <input name="referencePoint" type="text" value={referencePoint} onChange={(event) => setReferencePoint(event.target.value)} />
        </section>

      </Content>

      <Footer
        style={{
          background: districtId &&
            address.length > 0 ? data?.primary_color ? data?.primary_color : "#1e90ff" : "#7f8f9f",
          padding: 0
        }}
      >
        <button style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          background: districtId &&
            address.length > 0 ? data?.primary_color ? data?.primary_color : "#1e90ff" : "#7f8f9f",
          paddingLeft: 20,
          paddingRight: 20,
          color: '#fff',
          border: 0
        }} onClick={() => {
          if (
            districtId &&
            address.length > 0
          ) {
            handleAddress()
          } else {
            toast("Preencha todos os campos obrigatórios", { type: "warning" })
          }
        }}>
          <h1>Salvar endereço</h1>
          <FiArrowRight size={20} />
        </button>
      </Footer>
    </Container >
  );
};

export default OrderPage;
