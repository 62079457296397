import React, {useState, useEffect} from 'react';
import { FiArrowLeft, FiClock, FiDollarSign } from "react-icons/fi";
import { useHistory, useLocation, useParams } from 'react-router-dom';
/* Services */
import Services from '../../services/Services';
/* Context */
import { useMenu } from '../../context/MenuContext'
/* Styles */
import {
  Container,
  Header,
  Content,
  Nav,
  Divider
} from './styles';

const DetailPage: React.FC = () => {
  const history = useHistory()
  const location = useLocation<any>()
  const params = useParams<any>()
  const { data } = useMenu()
  const [dt] = useState(location?.state?.detail)
  const [payments, setPayments] = useState<any>([])

  useEffect(() => {
    (async () => {
      if(params.empresa !== data.empresa){
        history.push(`/${params.empresa}`)
      }
      await Services.payments(params.empresa)
        .then(response => {
          setPayments(response.data)
        }).catch(error => {})
    })()
  }, [params, data, history])

  return (
    <Container>
      <Header>
        <Nav>
          <FiArrowLeft cursor="pointer" size={20} onClick={() => history.goBack()} />
          <h1>Sobre a loja</h1>
          <div/>
        </Nav>
        <Divider />
      </Header>
      
      <Content>
        <section>
          <h1>
            <FiClock size={15} color="#30333c" style={{marginRight:10}} />
            Horário de funcionamento
          </h1>

          {dt.abre_domingo &&
            <p>Domingo {dt.hora_abre}h às {dt.hora_fecha}h</p>
          }
          {dt.abre_segunda &&
            <p>Segunda {dt.hora_abre}h às {dt.hora_fecha}h</p>
          }
          {dt.abre_terca &&
            <p>Terça {dt.hora_abre}h às {dt.hora_fecha}h</p>
          }
          {dt.abre_quarta &&
            <p>Quarta {dt.hora_abre}h às {dt.hora_fecha}h</p>
          }
          {dt.abre_quinta &&
            <p>Quinta {dt.hora_abre}h às {dt.hora_fecha}h</p>
          }
          {dt.abre_sexta &&
            <p>Sexta {dt.hora_abre}h às {dt.hora_fecha}h</p>
          }
          {dt.abre_sabado &&
            <p>Sábado {dt.hora_abre}h às {dt.hora_fecha}h</p>
          }
        </section>

        <section>
          <h1>
            <FiDollarSign size={15} color="#30333c" style={{marginRight:10}} />
            Formas de pagamento
          </h1>

          {payments.map((payment:any) => (
            <p>{payment.descricao}</p>
          ))}

        </section>
      </Content>
    </Container>
  );
};

export default DetailPage;
