import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
/* Styles */
import { GlobalStyles } from './styles/GlobalStyles';
/* Pages */
import OnboardingPage from './pages/OnboardingPage'
import MenuPage from './pages/MenuPage'
import DetailPage from './pages/DetailPage'
import OrderPage from './pages/OrderPage'
import AboutPage from './pages/AboutPage'
import DeliveryPage from './pages/DeliveryPage'
import PaymentPage from './pages/PaymentPage'
import SearchPage from './pages/SearchPage'
import NotFound from './pages/NotFound'
/* Context */
import { MenuProvider } from './context/MenuContext'
import { EmpresaProvider } from './context/EmpresaContext'

function App() {
  return (
    <MenuProvider>
      <Router>
        <Switch>
          <Route path="/" exact component={NotFound} />

          <Route path="/:empresa" exact component={OnboardingPage} />
          <Route path="/:empresa/menu" exact component={MenuPage} />
          <Route path="/:empresa/detail/:id_group/:product" exact component={DetailPage} />
          <Route path="/:empresa/order" exact component={OrderPage} />
          <Route path="/:empresa/about" exact component={AboutPage} />
          <Route path="/:empresa/delivery" exact component={DeliveryPage} />
          <Route path="/:empresa/payment" exact component={PaymentPage} />
          <Route path="/:empresa/search" exact component={SearchPage} />
        </Switch>
      </Router>
      <ToastContainer />
      <GlobalStyles />
    </MenuProvider>
  )
}

export default App;
