import React from 'react';
/* Logo */
import logo from '../../assets/images/logo.png'
/* Styles */
import { Container, Content, Logo} from './styles';

const OnboardingPage: React.FC = () => {
  return (
    <Container>
      <Content>
        <Logo src={logo} alt="Menu Dallas" />
        <h2>Estabelecimento não encontrado</h2>
      </Content>
    </Container>
  );
};

export default OnboardingPage;
