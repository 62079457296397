import styled from "styled-components";

interface CheckboxProps {
  checked: boolean;
  color?: string;
}

interface RadioProps {
  color?: string;
}

interface RefProps {
  colour: any
}

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #f3f5f7;
  overflow-x: hidden;
`;
export const Header = styled.header`
  background-color: #fff;
  width: 100%;
  max-width:700px;
  display: flex;
  flex-direction: column;
  .container_image{
    padding: 1rem;
    img{
      width: 100%;
      height: 150px;
      border-radius: 4px;
      object-fit: cover;
    }
  }

  .pricepromocao{
    color: #50a773;

    .valorwhitoutpromotion{
      color: #717171;
      text-decoration: line-through;
      margin-left: 11px;
    }
  }

  h2{
    font-size: 16px;
    font-weight: bold;
    line-height: 120%;
    padding: 0.5rem 1rem;
  }

  p {
    font-family: Roboto, sans-serif;
    margin-left: 10px;
    width: 100%;
    display: flex;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 17px;
    color: #7f8f9f;
    line-height: 120%;
    padding: 0.5rem;
  }

  .row{
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tag{
      height: 22px;
      padding:1rem;
      margin-right: 1rem;
      margin-bottom: 0.5rem;
      border-radius: 15px;
      border: 1px solid rgb(232, 234, 237);
      box-shadow: rgb(232 234 237) 0px 2px 4px;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      white-space: nowrap;
      color: #fff;
      background: rgb(0, 180, 164);
    }
  }
`;

export const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 4rem;
  border-bottom: 1px solid rgb(232, 234, 237);

  padding: 1rem;

  h1{
    margin-right: 35px;
    font-size: 15px;
    line-height: 150%;
    font-weight: 500;
    text-align: center;
    color: rgb(48, 51, 60);
  }
`;

export const Divider = styled.div`
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 15px;
  width: 100%;
  background-color: #fff;
  background-image: radial-gradient(circle at 10px 26px, #f3f5f7 20px, transparent 21px);
  content: "";
  background-size: 40px 40px;
  margin-bottom: -5px;
`;

export const Required = styled.div`
  padding: 4px 8px;
  margin-right: 0px;
  border-radius: 24px;
  opacity: 1;
  font-size: 12px;
  transition: all 0.3s ease 0s;
  font-weight: 600;
  text-align: center;
  visibility: visible;
  color: rgb(255, 255, 255);
  background: rgb(127, 143, 159);
`;

export const Radio = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius:12px;
  cursor: pointer;
  pointer-events: all;
  border: 1px solid #485460;
`;

export const RadioActive = styled.div<RadioProps>`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: ${props => props.color ? props.color : "#1e90ff"};
`;

export const Checkbox = styled.div<CheckboxProps>`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius:3px;
  cursor: pointer;
  pointer-events:all;
  background-color: ${props => props.checked ? props.color : "transparent"};
  border: 1px solid ${props => props.checked ? props.color : "#485460"};
`;

export const Count = styled.div`
  display: flex;
  align-items: center;

  font-size: 16px;
  line-height: 19px;
  text-align: center;

  button{
    padding: 5px;
    border: none;
    outline: none;
    background: transparent;
  }
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  min-height: 80vh;
  max-width:700px;
  padding-bottom: 8rem;

  /* Modal styles */
  .modal {
    position: fixed;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 5;
    height: 100%;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    overflow: hidden;
    transition: opacity linear 0.2s;
    overflow-y: auto;

    .modal_header {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 50px;
      border-top-right-radius: 50px;
      background-color: #fff;

      h1 {
        font-family: Roboto, sans-serif;
        font-size: 1.2rem;
        color: #30333c;
      }
    }

    button{
      background-color: #1e90ff;
      border: none;
      color:#fff;
      border-radius: 16px;
    }

    .modal_content {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      .modal_item {
        width: 100%;
        padding: 24px;
        border-bottom: 1px solid rgb(232, 234, 237);
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #1e90ff;
        text-decoration: none;
        p {
          font-family: Roboto, sans-serif;
          font-weight: 500;
          padding-left: 24px;
          display: flex;
          align-items: center;
          font-size: 16px;
          text-align: left;
          user-select: none;
        }
        span {
          font-family: Roboto, sans-serif;
          font-weight: 500;
          padding-left: 24px;
          display: flex;
          align-items: center;
          font-size: 12px;
          text-align: left;
          user-select: none;
          color: #30333c;
        }
      }

      button {
        width: 100%;
        height: 50px;
      }

      .form_input {
        width: 100%;
        label {
          font-family: Roboto, sans-serif;
          font-size: 1rem;
        }

        input {
          margin: 5px 0;
          width: 100%;
          resize: none;
          outline: none;
          padding: 10px 16px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid rgb(232, 234, 237);
          padding: 10px;
          border: none;
        }
      }
    }

    &:target {
      height: 100%;
      opacity: 1;
    }
    &__content {
      position: absolute;
      background: #f3f5f7;
      width: 100%;
      max-width: 1024px;
      border-top-left-radius: 50px;
      border-top-right-radius: 50px;
      z-index: 6;
      &__close {
        position: absolute;
        top: -10px;
        right: 5px;
        width: 50px;
        height: 50px;
        border-radius: 25px;
        text-align: center;
        padding: 5px;
        background: #2980b9;
        color: #fff;
      }
    }
  }

  section{
    width: 100%;
    .column{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      
      h2{
        font-size: 14px;
        font-weight: bold;
        word-break: break-word;
        letter-spacing: 0.01em;
        text-transform: capitalize;
        color: #485460;
      }
    }

    span{
      padding: 1rem;
      margin-top: 2px;
      font-size: 12px;
      font-weight: 500;
      line-height: 150%;
      color: rgb(127, 143, 159);
    }

    p{
      max-width: 800px;
      margin: 8px auto 0px;
      font-size: 12px;
      line-height: 150%;
      font-weight: 500;
      color: rgb(127, 143, 159);

      @media only screen and (max-width: 769px){
        padding: 7px;
        margin: 0 10px;
      }
    }

    textarea{
      width: 90%;
      resize: none;
      outline: none;
      padding: 10 16px;
      min-height: 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgb(232, 234, 237);
      padding: 10px;
      margin: 0 16px;
      border: none;
    }

    article{
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgb(232, 234, 237);
      padding: 10px 4px 10px 0px;
      margin: 0 16px;
      label{
        font-size: 14px;
        line-height: 150%;
        font-weight: 500;
        color: #485460;
      }

      .content{
        display: flex;
        align-items: center;
        strong{
          margin-right: 12px;
          font-size: 14px;
          line-height: 150%;
          font-weight: 600;
          text-align: right;
          white-space: nowrap;
          color: rgb(72, 84, 96);
        }

      }
    }
  }

`;

export const Footer = styled.footer<RefProps>`

  position: fixed;
  bottom: 0;
  background-color: #fff;
  min-height: 4rem;
  height: auto;
  width: 100%;
  max-width:700px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;

  .btn_container{
    width: 100px;
    height: 50px;
    border-radius: 4px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  
    button{
      width: 30px;
      height: 40px;
      border: none;
      border-radius: 4px;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      cursor: pointer;
      font-size: 3rem;
      user-select: none;
      color: rgb(127, 143, 159);
      background-color: rgb(232, 234, 237);
      outline: none;

      &:hover{
        background-color: ${props => props.colour};
        color: #fff;
      }
    }

    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
    color: rgb(72, 84, 96);
  
  }

  .btn{
    height: 40px;
    min-width: 200px;
    border: none;
    border-radius: 4px;
    display: flex;
    justify-content: space-around;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    background-color: #1e90ff;

    p{
      font-size: 14px;
      font-weight: 500;
      line-height: 150%;
      color: #fff;
    }
  }

  .close{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: center;
    justify-content: center;

    h2{
      font-size: 14px;
      font-family: Roboto, sans-serif;
      font-weight: bold;
      text-align: center;
      color: rgb(72, 84, 96);
      margin-bottom: 0.5rem;
    }
    
    p{
      font-size: 12px;
      font-family: Roboto, sans-serif;
      font-weight: bold;
      text-align: center;
      color: rgb(127, 143, 159);
      margin-bottom: 0.5rem;
    }

    button{
      height: 40px;
      width: 90%;
      border: none;
      border-radius: 4px;
      display: flex;
      justify-content: space-around;
      -webkit-box-align: center;
      align-items: center;
      cursor: none;
      pointer-events: none;
      background-color: rgb(232, 234, 237);
      color: rgb(127, 143, 159);
    }

  }

`;
