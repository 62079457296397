import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #f3f5f7;
`;
export const Header = styled.header`
  background-color: #fff;
  width: 100%;
  max-width: 1024px;
  min-height: 8rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  @media only screen and (min-width: 992px) and (max-width:1398px ){
    max-width: 1024px;
  }

  @media only screen and (min-width: 1400px){
    max-width: 1306px;
  }

  h2 {
    font-size: 16px;
    font-weight: bold;
    line-height: 120%;
    padding: 0.5rem 1rem;
  }

  .row {
    margin-top: 10px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
`;

export const Nav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 4rem;
  border-bottom: 1px solid rgb(232, 234, 237);

  padding: 1rem;

  h1 {
    margin-right: 35px;
    font-size: 15px;
    line-height: 150%;
    font-weight: 500;
    text-align: center;
    color: rgb(48, 51, 60);
  }
`;

export const Divider = styled.div`
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 15px;
  width: 100%;
  background-color: #fff;
  background-image: radial-gradient(
    circle at 10px 26px,
    #f3f5f7 20px,
    transparent 21px
  );
  content: "";
  background-size: 40px 40px;
  margin-bottom: -5px;
`;

export const Cart = styled.div`
  padding: 1rem;

  a.xIconBtn{
    text-decoration: none;

  .xIcon{
    padding-left: 8px;
    text-decoration: none;
    font-size: 15px;
    color: red;
  }
}

  .detalhesRodape{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;

    p{
      font-size: 14px !important;
    }
  }

  .bottomCard{
    right: 0px;
    bottom: 0px;
    left: 0px;
    height: 15px;
    background-image: radial-gradient( circle at 15px 25px,#f3f5f7 19px,transparent 13px );
    content: "";
    background-size: 40px 40px;
    margin-bottom: -20px;
    position: relative;
  }

  .modal {
    opacity: 0;
    position: fixed;
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.8);
    transition: opacity linear 0.2s;
    z-index: 5;

    display: flex;
    align-items: flex-end;
    justify-content: center;

    overflow-y: auto;

    .modal_header {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 50px;
      border-top-right-radius: 50px;
      background-color: #fff;
      padding: 1rem;

      h1 {
        font-family: Roboto, sans-serif;
        font-size: 0.7rem;
        width: 50%;
        text-align: center;
        color: #30333c;
      }
    }

    .modal_content {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      button {
        width: 100%;
        height: 50px;
      }

      .form_input {
        width: 100%;
        label {
          font-family: Roboto, sans-serif;
          font-size: 1rem;
        }

        input {
          margin: 5px 0;
          width: 100%;
          resize: none;
          outline: none;
          padding: 10px 16px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid rgb(232, 234, 237);
          padding: 10px;
          border: none;
        }
      }
    }

    &:target {
      height: 100%;
      opacity: 1;
    }
    &__content {
      position: absolute;
      background: #f3f5f7;
      width: 100%;
      max-width: 1024px;
      border-top-left-radius: 50px;
      border-top-right-radius: 50px;
      
      @media only screen and (min-width: 992px) and (max-width:1398px ){
        max-width: 1024px;
      }

      @media only screen and (min-width: 1400px){
        max-width: 1306px;
      }

      z-index: 6;
      &__close {
        position: absolute;
        top: -10px;
        right: 5px;
        width: 50px;
        height: 50px;
        border-radius: 25px;
        text-align: center;
        padding: 5px;
        background: #2980b9;
        color: #fff;
      }
    }
  }

  .detail{
    p{
      font-size: 12px;
    }
    padding-bottom: 7px;
    border-bottom: 1px solid rgb(232, 234, 237);
  }

  .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;

    &.headerColumn{
      width: 100%;
      padding-right: 15px; 
      padding-bottom: 0px;

      p{
        font-weight: 900;
        font-size: 14px;
      }

      strong{
        font-weight: 900;
        font-size: 14px;
      }
    }

    &.itemDestaque{
      padding-bottom: 5px;

      p{
        font-weight: 900;
        font-size: 14px;
      }

      .column{
        strong{
          font-weight: 900;
          font-size: 14px;
        }

        a{
          svg{
            font-size: 16px;
            top: 1.5px;
            position: relative;
          }
        }
      }
    }

    &.itemTotal{
      padding-top: 20px;
      padding-bottom: 10px;

      p{
        font-weight: 900;
        font-size: 16px;
      }

      strong{
        font-weight: 900;
        font-size: 16px;
      }
    }

    .x{
      position: relative;
      top: -1px;
      font-size: 12px;
    }

    p {
      font-size: 12px;
      font-weight: 600;
      line-height: 150%;
      color: #000;
    }

    strong {
      font-size: 12px;
      font-weight: 600;
      line-height: 150%;
      color: #000;
    }

    .column {
      display: flex;
      align-items: center;
    }
  }

  ul{
    margin-left: 18px;
    margin-right: 14px;

    li{
      color: #898c92;
      font-size: 12px;

      .item{
        padding: 1px;
        
        p{
          font-size: 14px;
          color: #898c92;
          font-weight: normal;
        }

        strong{
          font-size: 14px;
          color: #898c92;
          font-weight: normal;
        }
      }
    }
  }
`;
export const Button = styled.button`
  border: 1px solid;
  width: 100%;
  height: 40px;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  text-align: center;
  color: #1e90ff;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
`;

export const Required = styled.div`
  padding: 4px 8px;
  margin-right: 0px;
  border-radius: 24px;
  opacity: 1;
  font-size: 12px;
  transition: all 0.3s ease 0s;
  font-weight: 600;
  text-align: center;
  visibility: visible;
  color: rgb(255, 255, 255);
  background: rgb(127, 143, 159);
`;

export const Radio = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  cursor: pointer;
  pointer-events: all;
  border: 1px solid #485460;
`;

export const RadioActive = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #1e90ff;
`;

export const Address = styled.a`
  width: 90%;
  text-decoration: none;
  cursor: pointer;
  max-width: 1024px;
  min-height: 60px;
  border-radius: 5px;
  padding: 10px 12px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  background: #fff;
  border: 1px solid #1e90ff;
  margin-bottom: 1rem;

  @media only screen and (min-width: 992px) and (max-width:1398px ){
    max-width: 1024px;
  }

  @media only screen and (min-width: 1400px){
    max-width: 1306px;
  }

  h3 {
    font-family: Roboto, sans-serif;
    margin-left: 10px;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    line-height: 17px;
    color: #1e90ff;
  }
  .address {
    p {
      margin-bottom: 4px;
      display: block;
      color: rgb(127, 143, 159);
      font-size: 11px;
      font-weight: 500;
      line-height: 16px;
      text-align: left;
    }

    .row {
      display: flex;
      margin-left: 10px;

      strong {
        text-align: left;
        line-height: 21px;
        font-weight: 600;
        color: rgb(72, 84, 96);
      }

      .tag {
        padding: 3px;
        border-radius: 3px;
        font-size: 12px;
        font-weight: bold;
        margin-left: 5px;
        color: #fff;
        background: #1e90ff;
      }
    }
  }
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: 100%;
  max-width: 1024px;
  padding-bottom: 8rem;

  @media only screen and (min-width: 992px) and (max-width:1398px ){
    max-width: 1024px;
  }

  @media only screen and (min-width: 1400px){
    max-width: 1306px;
  }

  /* Modal styles */
  .modal {
    opacity: 0;
    position: fixed;
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.8);
    transition: opacity linear 0.2s;
    z-index: 5;

    display: flex;
    align-items: flex-end;
    justify-content: center;

    overflow-y: auto;

    .modal_header {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 50px;
      border-top-right-radius: 50px;
      background-color: #fff;

      h1 {
        font-family: Roboto, sans-serif;
        font-size: 1.2rem;
        color: #30333c;
      }
    }

    .modal_content {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      .modal_item {
        width: 100%;
        padding: 24px;
        border-bottom: 1px solid rgb(232, 234, 237);
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #1e90ff;
        text-decoration: none;
        p {
          font-family: Roboto, sans-serif;
          font-weight: 500;
          padding-left: 24px;
          display: flex;
          align-items: center;
          font-size: 16px;
          text-align: left;
          user-select: none;
        }
        span {
          font-family: Roboto, sans-serif;
          font-weight: 500;
          padding-left: 24px;
          display: flex;
          align-items: center;
          font-size: 12px;
          text-align: left;
          user-select: none;
          color: #30333c;
        }
      }

      button {
        width: 100%;
        height: 50px;
      }

      .form_input {
        width: 100%;
        label {
          font-family: Roboto, sans-serif;
          font-size: 1rem;
        }

        input {
          margin: 5px 0;
          width: 100%;
          resize: none;
          outline: none;
          padding: 10px 16px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid rgb(232, 234, 237);
          padding: 10px;
          border: none;
        }
      }
    }

    &:target {
      height: 100%;
      opacity: 1;
    }
    &__content {
      position: absolute;
      background: #f3f5f7;
      width: 100%;
      max-width: 1024px;
      border-top-left-radius: 50px;
      border-top-right-radius: 50px;

      @media only screen and (min-width: 992px) and (max-width:1398px ){
        max-width: 1024px;
      }

      @media only screen and (min-width: 1400px){
        max-width: 1306px;
      }

      z-index: 6;
      &__close {
        position: absolute;
        top: -10px;
        right: 5px;
        width: 50px;
        height: 50px;
        border-radius: 25px;
        text-align: center;
        padding: 5px;
        background: #2980b9;
        color: #fff;
      }
    }
  }

  section {
    width: 90%;
    .column {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;
      padding-left: 0;

      h2 {
        font-size: 14px;
        font-weight: bold;
        color: #485460;
      }
    }

    span {
      padding: 1rem;
      margin-top: 2px;
      font-size: 12px;
      font-weight: 500;
      line-height: 150%;
      color: rgb(127, 143, 159);
    }

    p {
      width: 100%;
      margin: 8px auto 0px;
      font-size: 12px;
      line-height: 150%;
      font-weight: 500;
      color: rgb(127, 143, 159);
    }

    textarea {
      width: 100%;
      resize: none;
      outline: none;
      padding: 10px 16px;
      min-height: 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgb(232, 234, 237);
      padding: 10px;
      border: none;
    }

    input {
      width: 100%;
      resize: none;
      outline: none;
      padding: 10px 16px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgb(232, 234, 237);
      padding: 10px;
      border: none;
    }

    .obsText {
      margin-top: 15px;
    }

    article {
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgb(232, 234, 237);
      padding: 10px 4px 10px 0px;

      label {
        font-size: 14px;
        line-height: 150%;
        font-weight: 500;
        color: #485460;
      }

      .content {
        display: flex;
        align-items: center;
        strong {
          margin-right: 12px;
          font-size: 14px;
          line-height: 150%;
          font-weight: 600;
          text-align: right;
          white-space: nowrap;
          color: rgb(72, 84, 96);
        }
      }
    }
  }
`;

export const Result = styled.div`
  width: 90%;
  margin-top: 1rem;
  section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(232, 234, 237);
    padding: 0.5rem;

    h2 {
      font-size: 18px;
      font-weight: 500;
      color: #485460;
    }
    
    strong {
      font-size: 18px;
      font-weight: bold;
      color: #485460;
    }
  }
`;

export const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  background-color: #1e90ff;
  height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  cursor: pointer;
  color: #fff;

  h1 {
    font-size: 16px;
  }
`;
