import styled from "styled-components";

interface RefProps {
  header: any
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* Modal styles */
  .modal {
    opacity: 0;
    position: fixed;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    transition: opacity linear 0.2s;
    z-index: 10;
    top: 0;

    display: flex;
    align-items: flex-end;
    justify-content: center;

    .modal_header {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 50px;
      border-top-right-radius: 50px;
      background-color: #fff;

      h1 {
        font-family: Roboto, sans-serif;
        font-size: 1.2rem;
        color: #30333c;
      }
    }

    .modal_content {
      padding: 1rem;
      display: flex;
      max-height: 80vh;
      flex-direction: column;
      align-items: center;
      overflow-y: scroll;

      .modal_item {
        width: 100%;
        padding: 24px;
        border-bottom: 1px solid rgb(232, 234, 237);
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        color: #1e90ff;
        text-decoration: none;
        p {
          font-family: Roboto, sans-serif;
          font-weight: 500;
          padding-left: 24px;
          display: flex;
          align-items: center;
          font-size: 16px;
          text-align: left;
          user-select: none;
        }
        span {
          font-family: Roboto, sans-serif;
          font-weight: 500;
          padding-left: 24px;
          display: flex;
          align-items: center;
          font-size: 12px;
          text-align: left;
          user-select: none;
          color: #30333c;
        }
      }

      button {
        width: 100%;
        height: 50px;
      }

      .form_input {
        width: 100%;
        label {
          font-family: Roboto, sans-serif;
          font-size: 1rem;
        }

        input {
          margin: 5px 0;
          width: 100%;
          resize: none;
          outline: none;
          padding: 10px 16px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid rgb(232, 234, 237);
          padding: 10px;
          border: none;
        }
      }
    }

    &:target {
      height: 100%;
      opacity: 1;
    }
    &__content {
      position: absolute;
      background: #f3f5f7;
      width: 100%;
      @media only screen and (min-width: 992px) and (max-width:1398px ){
        max-width: 1024px;
      }

      @media only screen and (min-width: 1400px){
        max-width: 1306px;
      }
      border-top-left-radius: 50px;
      border-top-right-radius: 50px;
      z-index: 6;
      &__close {
        position: absolute;
        top: -10px;
        right: 5px;
        width: 50px;
        height: 50px;
        border-radius: 25px;
        text-align: center;
        padding: 5px;
        background: #2980b9;
        color: #fff;
      }
    }
  }
`;
export const Header = styled.div`
  background-color: #1e90ff;
  width: 100%;
  display: flex;
  padding-top:1rem;
  padding-bottom:10px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 8;

  &.minimized {
    position: fixed;
    top: 0;
    min-height: 0;
  }
`;

export const RefContainer = styled.div<RefProps>`
  width: 100%;
  
  @media only screen and (min-width: 992px) and (max-width:1398px ){
    max-width: 1024px;
  }

  @media only screen and (min-width: 1400px){
    max-width: 1306px;
  }

  @media only screen and (max-width: 769px){
    padding-left: 15px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    padding-top: 10px;
  }

  margin: 1rem;
  border-top: 1px solid #eee;
  display: flex;
  flex-direction: row;
  justify-content: initial;
  align-items: center;
  background-color: #fff;
  z-index: 5;
  padding-top: 20px;
  padding-bottom: 20px;

  &.minimized {
    position: fixed;
    top: ${props => props.header - 14}px;
    height: 135px;
  }

  .owl-prev{
    position: absolute;
    background: #fff !important;
    border: 1px solid #c4c4c4 !important;
    border-radius: 100%;
    z-index: 6 !important;
    width: 39px;
    height: 39px;
    left: -27px;
    top: 55px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 769px){
      display: none;
    }

    @media only screen and (max-width: 1000px){
      left: 5px;
    }
    
    span{
      font-size: 30px;
      font-weight: 100;
      color: #333;
      height: 37px;
    }
  }

  .owl-next{
    position: absolute;
    background: #fff !important;
    border: 1px solid #c4c4c4 !important;
    border-radius: 100%;
    z-index: 6 !important;
    width: 39px;
    height: 39px;
    right: -12px;
    top: 55px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    
    @media only screen and (max-width: 769px){
      display: none;
    }

    @media only screen and (max-width: 1000px){
      right: 5px;
    }

    span{
      font-size: 30px;
      font-weight: 100;
      color: #333;
      height: 37px;
    }
  }
`;
export const RefItem = styled.a`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  margin-right: 20px;
  max-width: 97px;

  &:hover{
    .itemIcon{
      transform: scale(1.05);
    }

    span{
      color: #3f3e3e;
    }
  }

  .itemIcon{
    transition: 0.3s;
    height: 80px;
    display: flex;
    align-items: flex-end;

    .retangulo{
      height: 40px;
      background-color: #1e90ff;
      width: 99px;
      border-radius: 7px;
    }

    img{
      object-fit: contain;
      height: 68px;
      width: 62px;
      display: block;
      margin: 0px auto;
      margin-top: -30px;
    }
  }

  span{
    transition: color .2s ease-out;
    margin: 5px 0 0;
    color: #717171;
    white-space: normal;
    text-align: center;
    font-size: .875rem;
    line-height: .880rem;
    min-height: 28px;
    padding-top: 5px;
  }
`;

export const HeaderFooter = styled.div`
  width: 100%;
  max-width: 700px;
  height: 50px;

  @media only screen and (min-width: 992px) and (max-width:1398px ){
    max-width: 1064px;
  }

  @media only screen and (min-width: 1400px){
    max-width: 1306px;
  }

  display: flex;
  padding: 0 1rem;
  align-items: center;
  justify-content: space-between;

  p {
    font-family: Roboto, sans-serif;
    margin-left: 10px;
    width: 100%;
    display: flex;
    font-size: 1rem;
    font-weight: 500;
    line-height: 17px;
    color: #fff;
    cursor: pointer;
  }
`;

export const Logo = styled.img`
  width: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LogoSmall = styled.img`
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const Content = styled.main`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;
export const Info = styled.div`
  width: 90%;
  @media only screen and (min-width: 992px) and (max-width:1398px ){
    max-width: 1024px;
  }

  @media only screen and (min-width: 1400px){
    max-width: 1306px;
  }

  padding: 1rem 0;
  .item {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5rem;
    align-items: center;

    p {
      font-family: Roboto, sans-serif;
      margin-left: 10px;
      width: 100%;
      display: flex;
      font-size: 0.875rem;
      text-align: left;
      font-weight: 500;
      line-height: 17px;
      color: #7f8f9f;
      text-align: left;
    }
  }
`;
export const Status = styled.div`
  height: 22px;
  padding: 0px 8px;
  border-radius: 3px;
  border: 1px solid rgb(232, 234, 237);
  box-shadow: rgb(232 234 237) 0px 2px 4px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  white-space: nowrap;
  color: #fff;
  background: rgb(0, 180, 164);
`;
export const Address = styled.a`
  width: 90%;
  text-decoration: none;
  @media only screen and (min-width: 992px) and (max-width:1398px ){
    max-width: 1024px;
  }

  @media only screen and (min-width: 1400px){
    max-width: 1306px;
  }

  min-height: 60px;
  border-radius: 5px;
  padding: 1rem;
  display: flex;
  align-items: center;
  background: #fff;
  border: 1px solid #1e90ff;
  margin-bottom: 1rem;

  p {
    font-family: Roboto, sans-serif;
    margin-left: 10px;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #1e90ff;
  }

  .address{
    p{
      margin-bottom: 4px;
      display: block;
      color: rgb(127, 143, 159);
      font-size: 11px;
      font-weight: 500;
      line-height: 16px;
      text-align: left;
    }

    .row{
      display: flex;
      margin-left: 10px;

      strong{
        text-align: left;
        line-height: 21px;
        font-weight: 600;
        color: rgb(72, 84, 96);
      }

      .tag{
        padding: 3px;
        border-radius: 3px;
        font-size: 12px;
        font-weight: bold;
        margin-left:5px;
        color:#fff;
        background:#1e90ff;
      }
    }
  }
`;

export const Products = styled.div`
  padding-bottom: 4rem;
  @media only screen and (min-width: 992px) and (max-width:1398px ){
    max-width: 1024px;
  }

  @media only screen and (min-width: 1400px){
    max-width: 1306px;
  }
  
  width: 100%;

  .top_section {
    right: 0px;
    bottom: 0px;
    left: 0px;
    height: 15px;
    background-image: radial-gradient(
      circle at 10px 26px,
      #f3f5f7 20px,
      transparent 21px
    );
    content: "";
    background-size: 40px 40px;
    margin-bottom: -5px;
  }

  .info {
    background: #f3f5f7;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    div {
      width: 90%;
      padding: 10px;
      border: 1px dashed #30333c;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        margin-left: 6px;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        color: #30333c;
      }
    }
  }

  section {
    &.promocoes{
      .item{
        width: 100%;

        @media only screen and (max-width: 769px){
          height: 313px !important;

          img{
            max-width: 100% !important;
          }
        }
      }

      .owl-prev{
        position: absolute;
        background: #fff !important;
        border: 1px solid #c4c4c4 !important;
        border-radius: 100%;
        z-index: 6 !important;
        width: 39px;
        height: 39px;
        left: -27px;
        top: 165px;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;

        @media only screen and (max-width: 769px){
          display: none;
        }
        
        span{
          font-size: 30px;
          font-weight: 100;
          color: #333;
          height: 37px;
        }
      }

    .owl-next{
      position: absolute;
      background: #fff !important;
      border: 1px solid #c4c4c4 !important;
      border-radius: 100%;
      z-index: 6 !important;
      width: 39px;
      height: 39px;
      right: -27px;
      top: 165px;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      
      @media only screen and (max-width: 769px){
        display: none;
      }

      span{
        font-size: 30px;
        font-weight: 100;
        color: #333;
        height: 37px;
      }
    }

    .item{
      padding: 0px;
      display: block;
      height: 380px;

      &.noImage{
        height: 224px;
      }

      .imageProduct{
        max-width: 100%;

        img{
          grid-area: image;
          width: 100%;
          border-radius: 4px 4px 0 0;
          height: 157px;
        }
      }
      
      .productInfo{
        margin-bottom: 23px;
        padding: 0 20px;
        padding-top: 20px;
      }
    }

    }

    h1 {
      background-color: green;
      height: 60px;
      margin-bottom: 0px;
      padding: 24px 16px 16px;
      border-bottom: 1px solid rgb(232, 234, 237);
      font-size: 14px;
      font-weight: bold;
      word-break: break-word;
      letter-spacing: 0.01em;
      text-transform: capitalize;
      color: #485460;
      background: #f3f5f7;
    }

    .listProduts{
      display: flex;
      align-items: center;
      columns: 3;
      flex-wrap: wrap;
      margin-bottom: 20px;

      @media only screen and (max-width: 769px){
        columns: 1;

        .item{
          margin: 10px;
        }
      }
    }

    .item {
      height: 224px;
    border: 1px solid #f2f2f2;
    box-shadow: 0 1px 4px rgb(0 0 0 / 5%);
    border-radius: 4px;
    padding: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    min-width: 320px;
    position: relative;
    width: 49%;
    background: #fff;
    text-decoration: none;
    transition: .2s;
    margin-right: 1%;
    margin-top: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;

    @media only screen and (max-width: 769px){
    width: 100%;
    height: 159px;
    padding: 12px;
    }
    
      &:nth-child(2n){
        margin-right: 0px;
        margin-left: 1%;
      }
    
      &:hover {
        border: 1px solid #dbdad9;
      }

      .imageProduct{
        position: relative;
        width: 100%;
        max-width: 40%;

        img {
          width: 170px;
          height: 170px;
          /* width: 100%; */
          border-radius: 4px;
          object-fit: cover;
          object-position: center;
          float: right;

          @media only screen and (max-width: 769px){
            height: 132px;
            max-width: 127px;
          }
        }
      }

      .productInfo{
        display: grid;
        grid-area: info;
        grid-template-rows: 1fr;
        max-width: 65%;
        width: 100%;

        &.w-100_wImg{
          max-width: 100%;
        }

        .infosTop{
          margin-bottom: 23px;
          height: 149px;

          @media only screen and (max-width: 769px){
            height: 78px;
          }

          h2{
            display: grid;
            color: #3e3e3e;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.25rem;
            margin-top: 0;
            font-size: 1.125rem;
            line-height: 1.5rem;
            margin-bottom: 9px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 2;
          }

          p{
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            font-weight: lighter;
            color: #717171;
            word-break: break-word;
            margin-bottom: 10px;
            font-size: .875rem;
            line-height: 1.25rem;
            margin-bottom: 10px;
          }

          .serve{
            font-size: .875rem;
            line-height: 1rem;
            color: #3e3e3e;
            font-weight: 700;
            padding: 10px 0;
          }
        }

        .price{
          font-size: 1rem;
          line-height: 1.25rem;
          font-weight: 400;
          color: #3e3e3e;

          span{
            color: #717171;
          }

          &.pricepromocao{
            color: #50a773;

            .valorwhitoutpromotion{
              color: #717171;
              text-decoration: line-through;
              margin-left: 11px;
            }
          }
        }
      }
    }
  }
`;

export const Radio = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  cursor: pointer;
  pointer-events: all;
  border: 1px solid #485460;
`;

export const RadioActive = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #1e90ff;
`;

export const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  background-color: #1e90ff;
  height: 4rem;
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: #fff;
  z-index: 5;

  .item {
    width: 33.3%;
    display: flex;
    align-items: center;
    justify-content: center;

    .circle {
      position: relative;
      top: -5px;
      width: 20px;
      height: 20px;
      color: #30333c;
      background-color: #fff;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Roboto, sans-serif;
      font-size: 12px;
    }
  }

  p {
    font-size: 16px;
    text-align: center;
  }
`;
