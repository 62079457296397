import React, { useState, useEffect } from 'react';
import { FiArrowLeft, FiSearch, FiX } from "react-icons/fi";
import { useHistory, useParams } from 'react-router-dom';
/* Context */
import { useMenu } from '../../context/MenuContext'
/* Services */
import Services from '../../services/Services';
/* Styles */
import {
  Container,
  Header,
  Content,
  Nav,
  RefContainer,
  RefItem,
  Products
} from './styles';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const SearchPage: React.FC = () => {
  const history = useHistory()
  const params = useParams<any>()
  const { data } = useMenu()
  const [search, setSearch] = useState<string>("")
  const [products, setProducts] = useState<any>([])
  const [heightHeader, setHeightHeader] = useState<any>(document.getElementById("header")?.clientHeight)
  const [dataProducts, setDataProducts] = useState<any>([])
  const [dataGpProducts, setDataGpProducts] = useState<any>([])
  const [searchView, setSearchView] = useState<any>(0)

  useEffect(() => {
    if (params.empresa !== data.empresa) {
      history.push(`/${params.empresa}/search`)
    }

    window.addEventListener('scroll', function () {
      let header_height: any = document.getElementById("header")?.clientHeight
      let header_ref: any = document.getElementById("ref")?.offsetTop
      let yOffset = 0

      yOffset = document.documentElement.scrollTop;
      setHeightHeader(header_height)
      if (yOffset >= header_height) {
        document.getElementById("header")?.classList.add("minimized");
      } else {
        document.getElementById("header")?.classList.remove("minimized");
      }

      if (yOffset >= header_ref) {
        document.getElementById("ref")?.classList.add("minimized");
      } else {
        document.getElementById("ref")?.classList.remove("minimized");
      }
    })

    if (searchView == 0) {
      var searchRestore = localStorage.getItem("@menu-digital:search");

      if (searchRestore != null && searchRestore != "") {
        handleSeach(searchRestore)
        setSearch(searchRestore)
        setSearchView(1)
      }
    }

  }, [data, params, history])

  const handleSeach = async (name: string) => {
    if (name === "") {
      setDataProducts([])
      setDataGpProducts([])
      localStorage.setItem("@menu-digital:search", "")
    } else {
      await Services.search(name, data.empresa)
        .then(response => {
          setDataProducts(response.data)
          document.documentElement.scrollTop = 1
        }).catch(error => { })

      await Services.group_products(params?.empresa)
        .then(response => {
          setDataGpProducts(response.data);
          document.documentElement.scrollTop = 1
        });

      localStorage.setItem("@menu-digital:search", name)
    }
  }

  const money = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2
  })

  const filterGpProduct = (entry: any, data: any) => {
    return entry.id_grupo == data.id_grupo;
  }

  const options = {
    responsive: {
      0: {
        items: 3,
        autoWidth: true
      },
      400: {
        items: 3,
        autoWidth: true,
        margin: 5
      },
      600: {
        items: 5,
      },
      700: {
        items: 6,
      },
      1000: {
        items: 9,

      }
    },
  }

  const filterPromotionTamanhoArrayMenor = (tamanho: any) => {
    let arrayValores = [];

    if (tamanho.valor_g_promocao != undefined) {
      arrayValores.push(tamanho.valor_g_promocao);
    } else {
      arrayValores.push(tamanho.valor_g);
    }

    if (tamanho.valor_m_promocao != undefined) {
      arrayValores.push(tamanho.valor_m_promocao);
    } else {
      arrayValores.push(tamanho.valor_m);
    }

    if (tamanho.valor_p_promocao != undefined) {
      arrayValores.push(tamanho.valor_p_promocao);
    } else {
      arrayValores.push(tamanho.valor_p);
    }

    return Math.min(...arrayValores)
  }

  const filterPromotionTamanhoArrayMaior = (tamanho: any) => {
    let arrayValores = [];

    if (tamanho.valor_g_promocao != undefined) {
      arrayValores.push(tamanho.valor_g_promocao);
    } else {
      arrayValores.push(tamanho.valor_g);
    }

    if (tamanho.valor_m_promocao != undefined) {
      arrayValores.push(tamanho.valor_m_promocao);
    } else {
      arrayValores.push(tamanho.valor_m);
    }

    if (tamanho.valor_p_promocao != undefined) {
      arrayValores.push(tamanho.valor_p_promocao);
    } else {
      arrayValores.push(tamanho.valor_p);
    }

    return Math.max(...arrayValores)
  }

  return (
    <Container>
      <Header id="header" >
        <Nav>
          <FiArrowLeft cursor="pointer" size={20} onClick={() => history.push(`/${params.empresa}/menu`)} />
          <div className="form_search" style={{ borderColor: (data?.primary_color ? data?.primary_color : "#1e90ff") }}>
            <FiSearch size={20} color={data?.primary_color ? data?.primary_color : "#1e90ff"} />
            <input
              type="text"
              value={search}
              placeholder="Buscar por..."
              onChange={(event) => {
                setSearch(event.target.value)
                handleSeach(event.target.value)
              }}
            />
            <FiX
              size={20}
              color="#eee"
              onClick={() => {
                setSearch("")
                setDataProducts([])
                setDataGpProducts([])
              }}
            />
          </div>
        </Nav>
      </Header>

      <Content>
        {dataProducts.length > 0 ?
          <>
            <RefContainer id="ref" header={heightHeader}>
              <OwlCarousel margin={10} nav items={9} {...options}>
                {dataGpProducts?.map((item: any, index: number) => (
                  dataProducts?.filter((entry: any) => filterGpProduct(entry, item)).length > 0 &&
                  <AnchorLink offset='-140' style={{ textDecoration: 'none' }} href={`#${item.descricao}`}><RefItem key={index} className="scrollto">
                    <div className='itemIcon'>
                      <div className="retangulo" style={{ backgroundColor: data?.primary_color }}>
                        <img src={item.icone} alt={item.descricao} />
                      </div>
                    </div>
                    <span>{item.descricao}</span>
                  </RefItem></AnchorLink>
                ))}
              </OwlCarousel>
            </RefContainer>

            <Products>
              {/* {products.map((item: any) => (
                item?.produtos.length > 0 &&
                <section key={item.id} id={item.descricao} >
                  <h1>{item?.descricao}</h1>
                  <div className="section_container">

                    {item?.produtos.map((product: any) => (
                      <div
                        key={product.id}
                        className="item"
                        onClick={() =>
                          history.push({
                            pathname: '/detail',
                            state: { detail: product }
                          })}
                      >
                        <div className="column">
                          <h2>{product?.descricao}</h2>
                          <p>{product?.detalhe}</p>
                          <h2>
                            {product.tamanhos && <span>A partir de </span>} {product.valor !== undefined ? money.format(product?.valor) : "R$0,00"}
                          </h2>
                        </div>
                        {item.image !== undefined &&
                          <img src={item.image} alt={item.descricao} />
                        }
                      </div>
                    ))}
                  </div>
                </section>
              ))} */}

              {dataGpProducts?.map((item: any) => (
                dataProducts?.filter((entry: any) => filterGpProduct(entry, item)).length > 0 &&
                <section key={item.id} id={item.descricao} >
                  <h1>{item?.descricao}</h1>
                  <div className="section_container">

                    <div className='listProduts'>
                      {dataProducts?.filter((entry: any) => filterGpProduct(entry, item))?.map((product: any) => (
                        <div
                          key={product.id_produto}
                          className="item"
                          onClick={() =>
                            history.push({
                              pathname: `/${data.empresa}/detail/${dataGpProducts?.filter((entry: any) => filterGpProduct(entry, product))[0].id_grupo}/${product.id_produto}`,
                            })}
                        >
                          <div className={`productInfo ${product.foto_destaque == undefined ? 'w-100_wImg' : ''}`}>
                            <div className="infosTop">
                              <h2>{product?.descricao}</h2>
                              <p>{product?.detalhe}</p>

                              {product?.serve_qtd_pessoas ? (
                                <span className="serve">Serve {product?.serve_qtd_pessoas} pessoa{product?.serve_qtd_pessoas > 1 ? 's' : ''}</span>
                              ) : ""}
                            </div>
                            <h2 className={`price ${product?.em_promocao == true ? 'pricepromocao' : ''}`}>
                              {product.tamanhos && <span>De: </span>}

                              {["P", "O"].indexOf(product.tipo) > -1 &&
                                <div>
                                  {/* {money.format(product?.valor_de)} <span>até: </span> */}
                                  {/* {money.format(product?.valor_ate)} */}
                                  {money.format(filterPromotionTamanhoArrayMenor(product))} <span>até: </span>
                                  {money.format(filterPromotionTamanhoArrayMaior(product))}
                                </div>}
                              {["P", "O"].indexOf(product.tipo) === -1 && (product?.em_promocao == false ? money.format(product?.valor) : money.format(product?.valor_promocao))}
                              {["P", "O"].indexOf(product.tipo) === -1 && product?.em_promocao == true ? (<span className='valorwhitoutpromotion'>{product?.em_promocao == true && money.format(product?.valor)}</span>) : ''}
                            </h2>
                          </div>

                          {product.foto_destaque !== undefined ? (
                            <div className="imageProduct">

                              <img src={product.foto_destaque} alt={product.descricao} />
                            </div>
                          ) : ''}

                        </div>
                      ))}
                    </div>
                  </div>
                </section>
              ))}
            </Products>
          </>
          :
          <div className="none">
            <h1>Nenhuma busca até o momento</h1>
            <p>Faça sua primeira busca</p>
          </div>
        }
      </Content>
    </Container>
  );
};

export default SearchPage;
