import React from 'react';

import {Btn} from './styles'

interface IProps {
  children: React.ReactElement | string;
}

const Button: React.FC<
  IProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({children, ...buttonProps }) => {
  return (
    <Btn {...buttonProps} >
      {children}
    </Btn>
  );
};

export default Button;
