import React, { useState, useEffect } from 'react';
import { FiArrowLeft, FiX } from "react-icons/fi";
import { MdMonetizationOn, MdCreditCard } from "react-icons/md";
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CurrencyInput from 'react-currency-input-field';
import NumberFormat from "react-number-format";

/* Components */
import Load from '../../components/Load';
/* Image */
import pix from '../../assets/icon/pix.png'
import picpay from '../../assets/icon/picpay.png'
/* Context */
import { useMenu } from '../../context/MenuContext'
/* Services */
import Services from '../../services/Services';
/* Styles */
import {
  Container,
  Header,
  Content,
  Nav,
  Pix,
  PicPay
} from './styles';

const PaymentPage: React.FC = () => {
  const history = useHistory()
  const params = useParams<any>()
  const { updateOrder, bag, order, data } = useMenu()

  const [payments, setPayments] = useState<any>([])
  const [thing, setThing] = useState<any>(undefined)
  const [active, setActive] = useState<any>({})
  const [total, setTotal] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (params.empresa !== data.empresa) {
      history.push(`/${params.empresa}/payment`)
    }

    if (bag !== null && bag.length > 0) {
      let cont = 0
      let taxa = order.valor_taxa;
      bag.forEach(element => {
        cont = cont + element.valor_total;
      });
      if (taxa !== undefined) {
        setTotal(cont + taxa)
      } else {
        setTotal(cont)
      }
    }

    (async () => {
      await Services.payments(data.empresa)
        .then(response => {
          setPayments(response.data)
          setLoading(false)
        }).catch(error => {
          setLoading(false)
        })
    })()
  }, [bag, order, data, history, params])

  const handlePayment = () => {
    let dt = {
      id_forma: active.id,
      forma: active.descricao,
      valor_para_troco: active.requer_troco ? thing.replace(",", ".") : 0
    }
    updateOrder(dt)
    history.push(`/${data.empresa}/order`)
  }

  const handlePaymentCancel = () => {
    setThing("");
  }

  if (loading) {
    return <Load dtcolor={data?.primary_color} />
  }


  const formatCurrencyByEnd = (value: string): string => {
    if (!Number(value)) return "";

    const amount = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2
    }).format(parseFloat(value) / 100);

    // if (parseFloat(value) / 100 > 100000000) {
    //   const newFloat = parseFloat(`${value}`.substr(0, `${value}`.length - 1));

    //   const newAmount = new Intl.NumberFormat("pt-BR", {
    //     style: "currency",
    //     currency: "BRL",
    //     minimumFractionDigits: 2
    //   }).format(newFloat);
    //   console.log("LimitReach", newFloat, newAmount, value, amount);
    //   return `${newAmount}`;
    // }
    return `${amount}`;
  };

  return (
    <Container>
      <Header>
        <Nav>
          <FiArrowLeft cursor="pointer" size={20} onClick={() => history.push(`/${data.empresa}/order`)} />
          <h1>Formas de pagamento</h1>
          <div />
        </Nav>
      </Header>

      <Content>
        <section>
          <div className="column">
            <h2>Selecione a forma de pagamento</h2>
          </div>

          {payments.map(((element: any, index: number) => (
            <a href={`#select`} key={index} onClick={() => setActive(element)} >
              {element.descricao === "DINHEIRO" &&
                <MdMonetizationOn color="#485460" size={30} style={{ marginRight: 5 }} />
              }

              {element.descricao.substr(0, 6) === "CARTÃO" &&
                <MdCreditCard color="#485460" size={30} style={{ marginRight: 5 }} />
              }

              {element.descricao === "PIX" &&
                <Pix src={pix} alt="Pix" />
              }

              {element.descricao === "PIC PAY" &&
                <PicPay src={picpay} alt="PicPay" />
              }

              {element.descricao}
            </a>


          )))}
        </section>

        <div className="modal" id={"select"}>
          <div className="modal__content">
            {/* <a className="modal__content__close" href="#/" onClick={() => setThing("")} >
              <FiX color="#fff" size={40} />
            </a> */}
            <div className="modal_header">
              <h1>{active.descricao}</h1>
            </div>
            <div className="modal_content">
              {active.requer_troco &&
                <div className="form_input">
                  <label htmlFor="Valor para troco">Valor para troco</label>
                  {/* <CurrencyInput
                    name="troco"
                    prefix="R$ "
                    value={thing}
                    decimalsLimit={2}
                    format={rightToLeftFormatter}
                    onValueChange={(value: any) => {
                      setThing(value)
                    }}
                  /> */}

                  <NumberFormat
                    placeholder="R$ 0,00"
                    prefix="R$ "
                    value={thing}
                    thousandSeparator="."
                    decimalSeparator=","
                    inputMode="numeric"
                    format={formatCurrencyByEnd}
                    onValueChange={(value: any) => {
                      var valueFinal = value.formattedValue.replace('R$ ', '')
                      setThing(valueFinal)
                    }}
                    />
                      
                </div>
              }

              <div className='actionDinheiro'>
                {active.requer_troco ?
                  <a
                    href={(active.requer_troco && thing !== undefined) && Number(thing.replace(",", ".")) > total ? "#/" : "#select"}
                    style={{
                      backgroundColor:
                        active.requer_troco &&
                          thing !== undefined ? (data?.primary_color ? data?.primary_color : "#1e90ff") : "#485460"
                    }}
                    onClick={() => {
                      if (active.requer_troco && thing !== undefined) {
                        let troco = Number(thing.replace(",", "."))
                        if (troco < total) {
                          toast("Valor inferior ao total do pedido", { type: "warning" })
                        } else {
                          handlePayment()
                        }
                      } else {
                        toast("Adicione o valor para troco", { type: "warning" })
                      }
                    }}
                  >Confirmar forma de pagamento</a>
                  :
                  <a
                    href="#/"
                    onClick={() => {
                      handlePayment()
                    }}
                    style={{ backgroundColor: (data?.primary_color ? data?.primary_color : "#1e90ff") }}
                  >Confirmar forma de pagamento</a>
                }

                <a
                  href="#/"
                  onClick={() => {
                    handlePaymentCancel()
                  }}
                  style={{ backgroundColor: ("rgb(127,143,159)") }}
                >Cancelar</a>
              </div>

            </div>
          </div>
        </div>

      </Content>
    </Container>
  );
};

export default PaymentPage;