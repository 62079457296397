import styled from "styled-components";

export const Btn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #FFF;
  width:90%;
  height:60px;
  outline: none;
  border-radius: 7px;
  font-size: 1rem;
  color: #1E90FF;
  font-family: Roboto, sans-serif;
`;
